import { BASE_API_URL } from '../env';
import { getToken } from '../service/CookieManager';

type METHOD = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
const HEADERS = { 'Content-Type': 'application/json', 'Accept': 'application/json' };
const HEADERS_DOWNLOAD = {
    'Accept':
        'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
};

export const getParams = (params: any) => {
    if (!!params && typeof params === 'object' && params !== null) {
        return `?${Object.keys(params)
            .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
            .join('&')}`;
    }
    return '';
};

export const Method = {
    DOWNLOAD: 'downloadAPI',
    FETCH: 'fetch',
};

const callApi = (
    url: string,
    method: METHOD,
    body: any = null,
    queryParams: any = null,
    header: any = {},
    type = Method.FETCH,
) => {
    const token = getToken(url);
    const newUrl = queryParams ? `${BASE_API_URL}${url}${getParams(queryParams)}` : `${BASE_API_URL}${url}`;

    const options: any = {
        method,
        headers: {
            ...(type === Method.DOWNLOAD ? HEADERS_DOWNLOAD : HEADERS),
            Authorization: `Bearer ${token}`,
            ...header,
        },
    };

    if (body) {
        options.body = JSON.stringify(body);
    }

    return fetch(newUrl, options).then(
        (response) => {
            const formattedResponse = type === Method.FETCH ? response.json() : response.blob();
            return formattedResponse.then((data) => ({
                data,
                status: response.status > 199 && response.status < 300,
            }));
        },
        // eslint-disable-next-line no-console
        (err) => console.error('err', err),
    );
};

export const uploadImageApiCall = async (url: string, formData: any) => {
    const token = getToken(url);
    const data = await fetch(`${BASE_API_URL}${url}`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
    });
    return await data.json();
};

export const downloadSalesReport = (response: any) => {
    if (response?.status) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${'SalesReport'}.pdf`);
        document.body.appendChild(link);
        link.click();
    }
};

export default callApi;
