/* eslint-disable no-unused-vars */
import { Dropdown, MenuProps } from 'antd';
import { useEffect, useState } from 'react';

import { UserOutlined } from '@ant-design/icons';

import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import LogoutIcon from '../../../assets/svg/ic_outline-logout.svg';
import ProfileIcon from '../../../assets/svg/profile_logo.svg';
import { deleteToken, getUserDetail } from '../../../service/CookieManager';
import { updateAdminUserAction } from '../../actions';
import callApi from '../../../Api/config';
import { USER_DETAIL_BY_EMAIL } from '../../../constants/EntityName';
import { toastError } from '../../../service/Toast';

const DefaultProfileHeader = () => {
    const initialUserDetail: any = getUserDetail() ? JSON.parse(getUserDetail() || '{}') : null;
    const [userDetail, setUserDetail] = useState(initialUserDetail);
    const history = useHistory();

    const dispatch = useDispatch();

    const fetchUserDetail = async () => {
        try {
            const response = await callApi(USER_DETAIL_BY_EMAIL, 'GET', undefined, { email: userDetail?.email });

            if (response?.status) {
                setUserDetail(response.data.data);
            } else {
                toastError('Error fetching User Detail');
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error during API hit:', error);
        }
    };

    useEffect(() => {
        fetchUserDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleProfileClick = () => {
        dispatch(updateAdminUserAction(userDetail));
        history.push({
            pathname: '/admin',
            state: { userDetail },
        });
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '12px',
                        paddingBottom: '12px',
                        paddingRight: '4px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}>
                    <span
                        style={{
                            fontSize: '1rem',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            cursor: 'default',
                        }}>
                        {userDetail?.fullName
                            ?.split(' ')
                            .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join(' ')}
                    </span>
                    <span
                        style={{
                            fontSize: '0.875rem',
                            fontWeight: '400',
                            color: '#A4A4A4',
                        }}>
                        {userDetail?.roleName}
                    </span>
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        paddingTop: '12px',
                        paddingBottom: '12px',
                        width: '100%',
                        cursor: 'pointer',
                    }}
                    onClick={handleProfileClick}>
                    <UserOutlined />
                    <p style={{ margin: 0 }}>Profile</p>
                </div>
            ),
        },
    ];

    return (
        <div className='defaultProfileActionbar'>
            <div className='flex align-bottom'>
                <h2>Super Admin</h2>
            </div>
            <div className='flex align-center' style={{ marginRight: '14px' }}>
                <div
                    className='flex align-center'
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        deleteToken();
                        window.location.reload();
                    }}>
                    <img src={LogoutIcon} alt='logout' height={21} width={21} style={{ marginRight: '10px' }} />
                    <p style={{ marginBottom: 0, fontSize: '16px', fontFamily: 'Montserrat', fontWeight: 500 }}>
                        Log out
                    </p>
                </div>
                <Dropdown menu={{ items }} placement='bottomRight'>
                    <div>
                        {userDetail?.fullName ? (
                            <div className='profile-icon'>
                                {userDetail?.fullName?.split(' ')[0][0].toUpperCase()}
                                {userDetail?.fullName
                                    ?.split(' ')
                                    [userDetail?.fullName?.split(' ').length - 1][0].toUpperCase()}
                            </div>
                        ) : (
                            <img
                                src={ProfileIcon}
                                alt='logout'
                                height={35}
                                width={35}
                                style={{ marginLeft: '20px', cursor: 'pointer' }}
                            />
                        )}
                    </div>
                </Dropdown>
            </div>
        </div>
    );
};

DefaultProfileHeader.defaultProps = {
    addName: '',
};

export default DefaultProfileHeader;
