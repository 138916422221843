/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Dropdown, Button, Checkbox } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import FilterIcon from '../../assets/svg/filterIcon.svg';
import './styles.scss';
import { POLICY_MODULE } from '../../constants/EntityName';

const StatusDropdown = ({
    filterOption,
    filterKey,
    entityName,
}: {
    filterOption: string[];
    filterKey: string;
    entityName: string;
}) => {
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const selected = params.getAll(filterKey);
        setSelectedStatuses(selected);
    }, [location.search, filterKey]);

    const handleChange = (checkedValues: any) => {
        if (entityName === POLICY_MODULE) {
            const newStatus = checkedValues.find((value: string) => !selectedStatuses.includes(value));
            if (newStatus) {
                setSelectedStatuses([newStatus]);
            } else {
                setSelectedStatuses(checkedValues);
            }
        } else {
            setSelectedStatuses(checkedValues);
        }
    };

    const handleReset = () => {
        setSelectedStatuses([]);

        const params = new URLSearchParams(location.search);
        params.delete(filterKey);
        history.push({
            pathname: location.pathname,
            search: `?${params.toString()}`,
        });

        setDropdownVisible(false);
    };

    const handleOk = () => {
        const params = new URLSearchParams(location.search);

        params.delete(filterKey);

        selectedStatuses.forEach((status) => {
            params.append(filterKey, status);
        });

        history.push({
            pathname: location.pathname,
            search: `?${params.toString()}`,
        });

        setDropdownVisible(false);
    };

    const menu = (
        <div className='status-dropdown-menu absolute-dropdown'>
            <Checkbox.Group
                options={filterOption}
                value={selectedStatuses}
                onChange={handleChange}
                className='status-checkbox-group'
            />
            <div className='dropdown-footer'>
                <Button onClick={handleReset} className='reset-button'>
                    Reset
                </Button>
                <Button type='primary' onClick={handleOk}>
                    OK
                </Button>
            </div>
        </div>
    );

    return (
        <Dropdown
            overlay={menu}
            trigger={['click']}
            placement='bottomLeft'
            visible={dropdownVisible}
            onVisibleChange={(visible) => setDropdownVisible(visible)}>
            <img style={{ cursor: 'pointer' }} src={FilterIcon} alt='Filter Icon' height={11} width={10} />
        </Dropdown>
    );
};

export default StatusDropdown;
