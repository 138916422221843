/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Table, Button, message, Select, Input, Spin } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';

import './style.scss';
import { useHistory } from 'react-router-dom';

import callApi from '../../../Api/config';
import {
    APPLICATION,
    CREATE_PREMIUM_PROCESS,
    PREMIUM_SPLIT_MARK_PAID,
    PREMIUM_SPLIT_SCHEDULE,
} from '../../../constants/EntityName';
import SplitProcessTable from './SplitProcessTable';
import ModalComponent from '../../../components/ModalComponent';
import { toastError, toastSuccess } from '../../../service/Toast';

interface PendingDataType {
    applicationId: string;
    companyName: string;
    planName: string;
    planNumber: string;
    premiumSplitSchedule: string | null;
    key: string;
}

interface ScheduleName {
    id: string;
    name: string;
}

interface SelectedRecordData {
    applicationId: string;
    companyName: string;
    planName: string;
    planNumber: string;
    premiumScheduleId: string;
    premiumSplitSchedule: string;
}

const PremiumSplitProcess: React.FC = () => {
    const [activeTab, setActiveTab] = useState<'pending' | 'scheduled'>('pending');
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [pendingData, setPendingData] = useState<PendingDataType[]>([]);
    const [totalCount, setTotalCount] = useState<number>();
    const [scheduleNames, setScheduleNames] = useState<ScheduleName[]>([]);
    const [applicationPreviewModal, setApplicationPreviewModal] = useState<boolean>(false);
    const [selectedRecordData, setSelectedRecordData] = useState<SelectedRecordData[]>([]);
    const [checkedRowKeys, setCheckedRowKeys] = useState<any>({});
    const [markPaidModal, setMarkPaidModal] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [isSplitLoading, setIsSplitLoading] = useState<boolean>(false);

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
    });

    const history = useHistory();

    let previousPaginationCount = 1;

    const setPaginationCount = (currentPaginationCount: number) => {
        if (currentPaginationCount === previousPaginationCount) {
            return;
        }
        previousPaginationCount = currentPaginationCount;
        setTotalCount(currentPaginationCount);
    };

    const fetchScheduleNames = async () => {
        try {
            const response = await callApi(PREMIUM_SPLIT_SCHEDULE, 'GET');
            const results: ScheduleName[] = response?.data?.data?.results.map(
                (item: { id: string; premiumScheduleName: string }) => ({
                    id: item.id,
                    name: item.premiumScheduleName,
                }),
            );

            setScheduleNames(results);
        } catch (error) {
            console.error('Error during API hit:', error);
            message.error('Failed to fetch premium schedule names');
        }
    };

    const handleScheduleNameSelect = (selectedId: string, rowRecord: PendingDataType) => {
        const selectedSchedule = scheduleNames?.find((schedule) => schedule?.id === selectedId);

        if (selectedSchedule) {
            const selectedData: SelectedRecordData = {
                applicationId: rowRecord?.applicationId,
                companyName: rowRecord?.companyName,
                planName: rowRecord?.planName,
                planNumber: rowRecord?.planNumber,
                premiumScheduleId: selectedId,
                premiumSplitSchedule: selectedSchedule?.name,
            };

            setSelectedRecordData((prev) => {
                const isApplicationIdExist = prev.find((item) => item?.applicationId === rowRecord?.applicationId);

                if (isApplicationIdExist) {
                    const newData = {
                        ...isApplicationIdExist,
                        ...selectedData,
                    };
                    return prev.map((item) => (item?.applicationId === rowRecord?.applicationId ? newData : item));
                } else {
                    return [...prev, selectedData];
                }
            });
        }
    };

    const pendingColumns: ColumnsType<PendingDataType> = [
        {
            title: 'Application ID',
            dataIndex: 'applicationId',
            key: 'applicationId',
            width: 200,
            render: (text) => (
                <span
                    onClick={() => history.push(`${APPLICATION}/${text}`)}
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    {text}
                </span>
            ),
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 300,
            render: (text: string) => <span style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>{text}</span>,
        },
        {
            title: 'Plan Name',
            dataIndex: 'planName',
            key: 'planName',
            width: 300,
            render: (text: string) => (
                <span style={{ wordBreak: 'break-word', whiteSpace: 'normal' }} className='schedule-name'>
                    {text}
                </span>
            ),
        },
        {
            title: 'Plan Number',
            dataIndex: 'planNumber',
            key: 'planNumber',
            width: 300,
            render: (text: string) => (
                <span style={{ wordBreak: 'break-word', whiteSpace: 'normal' }} className='schedule-name'>
                    {text}
                </span>
            ),
        },
        {
            title: 'Premium Split Schedule',
            dataIndex: 'premiumSplitSchedule',
            key: 'premiumSplitSchedule',
            render: (text, record) => {
                const selectedRecord = selectedRecordData.find((item) => item?.applicationId === record?.applicationId);

                const defaultValue = selectedRecord ? selectedRecord?.premiumScheduleId : text || 'Select Schedule';

                return (
                    <Select
                        value={defaultValue}
                        className='split-schedule-drop'
                        onSelect={(value: string) => handleScheduleNameSelect(value, record)}>
                        {scheduleNames.map((schedule) => (
                            <Select.Option key={schedule?.id} value={schedule?.id}>
                                <span style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>{schedule?.name}</span>
                            </Select.Option>
                        ))}
                    </Select>
                );
            },
        },
    ];

    const fetchApplicationData = async (paginationEvent = pagination) => {
        const queryParams = {
            premiumSplitStatus: 'Pending for allocation',
            page: paginationEvent?.current,
            limit: paginationEvent?.pageSize,
        };

        try {
            const response = await callApi(APPLICATION, 'GET', null, queryParams);
            const totalCount = response?.data?.data?.total;

            setPaginationCount(totalCount);

            const fetchedData = response?.data?.data?.results.map((item: any) => ({
                key: item?.id,
                applicationId: item?.id,
                companyName: item?.companyName,
                planName: item?.planName,
                planNumber: item?.planNumber,
                premiumSplitSchedule: item?.premiumSplitSchedule,
            }));

            setPendingData(fetchedData);
        } catch (error) {
            console.error('Error during API hit:', error);
        }
    };

    useEffect(() => {
        fetchApplicationData();
        fetchScheduleNames();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onPageChange = (paginationEvent: TablePaginationConfig) => {
        setPagination(paginationEvent);
        fetchApplicationData(paginationEvent);
    };

    const handleProcessPendingApplication = async () => {
        setLoading(true);
        try {
            const payload = {
                premiumItems: selectedRecordData?.map((item) => ({
                    applicationId: item?.applicationId,
                    premiumScheduleId: item?.premiumScheduleId,
                })),
            };
            const response = await callApi(CREATE_PREMIUM_PROCESS, 'POST', payload);
            if (response?.status) {
                message.success('Premium split process completed successfully.');
                setApplicationPreviewModal(false);
                setSelectedRowKeys([]);
                fetchApplicationData();
                setActiveTab('scheduled');
            } else {
                toastError(response?.data?.error);
            }
        } catch (error) {
            console.error('Error during API hit:', error);
            message.error('Failed to process premium split.');
        } finally {
            setLoading(false);
        }
    };

    const isCheckboxDisable = (record: PendingDataType): boolean =>
        !selectedRecordData?.some((item) => item?.applicationId === record?.applicationId);

    const handleScheduleMarkPaid = async () => {
        if (!comment.trim()) {
            toastError('Please provide a comment.');
            return;
        }
        setIsSplitLoading(true);
        const applicationId = Object.values(checkedRowKeys).flat();
        const payload = {
            'applicationId': applicationId,
            'comment': comment,
        };
        try {
            const response = await callApi(PREMIUM_SPLIT_MARK_PAID, 'POST', payload);

            if (response?.status) {
                toastSuccess('Applications marked paid successfully.');
                setMarkPaidModal(false);
                setComment('');
                setCheckedRowKeys({});
            }
        } catch (error) {
            console.error('Error during API hit:', error);
        } finally {
            setIsSplitLoading(false);
        }
    };

    const handleTabSwitch = () => {
        setActiveTab('pending');
        setComment('');
        setCheckedRowKeys({});
    };

    return (
        <div className='premium-split-container'>
            <div className='header'>
                <div className='tab-buttons'>
                    <Button
                        type={activeTab === 'pending' ? 'primary' : 'default'}
                        onClick={() => handleTabSwitch()}
                        className={`tab-button ${activeTab === 'pending' ? 'active' : ''}`}>
                        Pending Premium Split
                    </Button>
                    <Button
                        type={activeTab === 'scheduled' ? 'primary' : 'default'}
                        onClick={() => setActiveTab('scheduled')}
                        className={`tab-button ${activeTab === 'scheduled' ? 'active' : ''}`}>
                        Scheduled Premium Split
                    </Button>
                </div>
                {activeTab === 'pending' && (
                    <Button
                        type='default'
                        icon={<SyncOutlined />}
                        className='process-button'
                        onClick={() => setApplicationPreviewModal(true)}
                        disabled={selectedRowKeys?.length === 0}>
                        Process premium split
                    </Button>
                )}
                {activeTab === 'scheduled' && (
                    <Button
                        type='default'
                        className='process-button'
                        onClick={() => setMarkPaidModal(true)}
                        disabled={Object.keys(checkedRowKeys)?.length !== 1}>
                        Mark as Paid
                    </Button>
                )}
            </div>

            {activeTab === 'pending' ? (
                <Table
                    onChange={onPageChange}
                    columns={pendingColumns}
                    dataSource={pendingData}
                    pagination={{
                        ...pagination,
                        total: totalCount,
                        showSizeChanger: false,
                    }}
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys,
                        onChange: (record) => setSelectedRowKeys(record),
                        getCheckboxProps: (record) => ({
                            checked: selectedRowKeys?.includes(record.applicationId),
                            disabled: isCheckboxDisable(record),
                        }),
                    }}
                    scroll={{
                        y: 500,
                        x: 'max-content',
                    }}
                />
            ) : !isSplitLoading ? (
                <SplitProcessTable setCheckedRowKeys={setCheckedRowKeys} />
            ) : (
                <Spin spinning={isSplitLoading} />
            )}

            <ModalComponent
                isOpen={markPaidModal}
                width='650px'
                height='420px'
                isCrossIcon={false}
                customClass='mark-paid-class'>
                <div>
                    <div className='mark-paid-title'>
                        <h2>Provide comment</h2>
                    </div>
                    <div className='mark-paid-container'>
                        <p>Please provide a comment to mark application as Paid.</p>
                        <Input.TextArea
                            maxLength={500}
                            rows={4}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder='Type here...'
                        />
                    </div>
                    <div className='mark-paid-footer'>
                        <Button key='decline' onClick={() => setMarkPaidModal(false)} className='decline-btn'>
                            Cancel
                        </Button>
                        <Button
                            key='approve'
                            type='primary'
                            loading={isSplitLoading}
                            onClick={() => handleScheduleMarkPaid()}
                            className='approve-btn'>
                            Mark Paid
                        </Button>
                    </div>
                </div>
            </ModalComponent>

            <ModalComponent
                isOpen={applicationPreviewModal}
                width='1200px'
                height='720px'
                isCrossIcon={false}
                customClass='application-review-class'>
                <div>
                    <div className='application-review-heading'>
                        <h2>Approved applications preview</h2>
                    </div>
                    <div className='application-review-body'>
                        <div className='application-card'>
                            {selectedRecordData.map((app, index) => (
                                <div key={app?.applicationId} className='application-card-container'>
                                    <h3>Application {index + 1}</h3>
                                    <div className='application-details'>
                                        <div>
                                            <p className='field-label'>Applicant ID</p>
                                            <p className='field-value'>{app?.applicationId}</p>
                                        </div>
                                        <div>
                                            <p className='field-label'>Company Name</p>
                                            <p className='field-value'>{app?.companyName}</p>
                                        </div>
                                        <div>
                                            <p className='field-label'>Plan Name</p>
                                            <p className='field-value'>{app?.planName}</p>
                                        </div>
                                        <div>
                                            <p className='field-label'>Plan Number</p>
                                            <p className='field-value'>{app?.planNumber}</p>
                                        </div>
                                        <div>
                                            <p className='field-label'>Split Schedule</p>
                                            <p className='field-value'>{app?.premiumSplitSchedule}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='action-buttons'>
                            <Button
                                className='cancel-btn'
                                onClick={() => {
                                    setSelectedRowKeys([]);
                                    setSelectedRecordData([]);
                                    setApplicationPreviewModal(false);
                                }}>
                                Cancel
                            </Button>
                            <Button className='submit-btn' onClick={handleProcessPendingApplication} loading={loading}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
            </ModalComponent>
        </div>
    );
};

export default PremiumSplitProcess;
