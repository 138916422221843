import { customErrorMsg, errorMsg } from '../constants/messages';

const userListFormData = [
    {
        title: 'User Details',
        name: 'fullName',
        label: 'Full Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Full Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'email',
        label: 'Email',
        fieldType: {
            name: 'email',
        },
        rules: [{ message: customErrorMsg('Please enter email Id') }],
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Email' },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'phoneNumber',
        label: 'Phone',
        fieldType: {
            name: 'text',
        },
        rules: [
            {
                pattern: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
                message: 'Please enter a valid phone number',
            },
        ],
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Phone' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'userCompany',
        label: 'Company Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Company Name' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'title',
        label: 'Title',
        fieldType: {
            name: 'select',
            options: [],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Title' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'roleName',
        label: 'Role',
        fieldType: {
            name: 'select',
            options: [
                { name: 'Advisor', value: 'Advisor' },
                { name: 'Agent', value: 'Agent' },
                { name: 'Plan Sponsor', value: 'Plan Sponsor' },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Role',
            filterOptions: ['Advisor', 'Agent', 'Plan Sponsor'],
            filterKey: 'userRole',
            fiterIcon: true,
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'planSponsorCompany',
        label: 'Plan Sponsor Company',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Plan Sponsor Company' },
        isTableHeader: true,
        isAccordian: false,
    },
    // this code will be in use to set permission and access
    // {
    //     name: 'permissions',
    //     label: '',
    //     fieldType: {
    //         name: 'permissions',
    //     },
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'permissions', columnType: 'permissions' },
    //     isTableHeader: false,
    //     isAccordian: true,
    //     default: '',
    // },
];

export { userListFormData };
