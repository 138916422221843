const allowTableShort = (enableSort: boolean, tableKey: string) => {
    if (!enableSort) {
        return false;
    }
    if (enableSort && tableKey === 'submittedDate') {
        return {
            compare: (a: any, b: any): any => {
                const dateA: any = new Date(a.submittedDate);
                const dateB: any = new Date(b.submittedDate);
                return dateB - dateA;
            },
        };
    }
    if (enableSort && tableKey === 'updatedAt') {
        return {
            compare: (a: any, b: any): any => {
                const dateA: any = new Date(a.updatedAt);
                const dateB: any = new Date(b.updatedAt);
                return dateB - dateA;
            },
        };
    }
    if (enableSort && tableKey === 'createdAt') {
        return {
            compare: (a: any, b: any): any => {
                const dateA: any = new Date(a.createdAt);
                const dateB: any = new Date(b.createdAt);
                return dateB - dateA;
            },
        };
    }
};

export default allowTableShort;
