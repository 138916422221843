// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filterBody {
  display: flex;
  justify-content: space-around; }
  .filterBody .ant-select-selector {
    width: 250px !important; }
`, "",{"version":3,"sources":["webpack://./src/components/FilterModal/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,6BAA6B,EAAA;EAFjC;IAKQ,uBAAuB,EAAA","sourcesContent":[".filterBody{\n    display: flex;\n    justify-content: space-around;\n\n    .ant-select-selector{\n        width: 250px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
