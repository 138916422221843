import { Form, FormInstance, Input } from 'antd';

import { useState } from 'react';

import { InputInterface } from '../utils/forms/FormInterface';

import './style.scss';
import { formatPhoneNumber } from '../utils/functions';

export interface InputProps {
    formInput: InputInterface;
    index: number;
    form?: FormInstance;
    entityName?: string;
}

const TextInput = (props: InputProps) => {
    const { formInput, index, form, entityName } = props;
    const [inputValue, setInputValue] = useState<string>('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target;
        if (entityName === 'user' && formInput.name === 'phoneNumber') {
            value = formatPhoneNumber(value);
        }
        setInputValue(value);
        form?.setFieldsValue({ [formInput.name]: value });
    };

    return (
        <Form.Item
            key={index}
            wrapperCol={{ span: 8, offset: 4 }}
            labelCol={{ span: 4 }}
            label={<div className={!formInput.required ? 'leftOffset' : ''}>{formInput.label}</div>}
            colon={formInput.required}
            labelAlign='left'
            name={formInput.name}
            rules={[
                {
                    required: formInput.required,
                    message: formInput.errorMsg,
                },
                ...((formInput.rules ? formInput.rules : []) as Array<any>),
            ]}>
            <Input
                maxLength={entityName === 'user' && formInput.name === 'phoneNumber' ? 12 : 256}
                disabled={formInput.readonly}
                value={inputValue}
                onChange={handleInputChange}
            />
        </Form.Item>
    );
};

export default TextInput;
