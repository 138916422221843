import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { useSelector } from 'react-redux';

import Sidebar from '../../components/SideBar';
import { AppRoutes, entityArray } from '../../constants/AppConstant';

import Users from '../Users';
import CustodiaLogo from '../../assets/png/CustodiaLogo.png';

import UpdatePassword from '../UpdatePassword';
import Applications from '../Applications';
import PlanSponsor from '../PlanSponsors';
import ApplicationDetailPage from '../Applications/ApplicationDetailPage';
import CreateUser from '../CreateUser';
import CreatePlanSponsor from '../CreatePlanSponsor';
import AdminUserModule from '../AdminUserModule';
import CreateAdmin from '../CreateAdmin';
import './styles.scss';
import PolicyModule from '../PolicyModule';
import DefaultProfileHeader from '../Entity/AbstractLanding/DefaultProfileHeader';
import FooterComponent from '../../components/FooterComponent';

const Landing = () => {
    const [moduleList] = useState<any>(entityArray);

    const record = useSelector((state: any) => state?.globalReducer?.record);

    const isCreateMode = Object.keys(record).length > 0;

    return (
        <Router>
            <div className='landing-box' style={{ display: 'flex' }}>
                <div style={{ width: '237px' }}>
                    <Sidebar title='Custodia' entityArr={moduleList} logo={CustodiaLogo} />
                </div>

                <div className='landing-box-content' style={{ flexGrow: 1 }}>
                    <DefaultProfileHeader />

                    <Switch>
                        <Route exact path='/' component={Applications} />
                        <Route exact path={AppRoutes.Applications} component={Applications} />

                        <Route exact path={`${AppRoutes.Users}`}>
                            <Users />
                        </Route>

                        <Route exact path={AppRoutes.CreateUser}>
                            <CreateUser />
                        </Route>

                        <Route exact path={AppRoutes.CreatePlanSponsor}>
                            <CreatePlanSponsor />
                        </Route>

                        <Route exact path={`${AppRoutes.UpdatePassword}`}>
                            <UpdatePassword />
                        </Route>

                        <Route exact path={`${AppRoutes.Applications}`}>
                            <Applications />
                        </Route>

                        <Route exact path={`${AppRoutes.Applications}/:id`}>
                            <ApplicationDetailPage />
                        </Route>

                        <Route exact path={`${AppRoutes.PlanSponsor}`}>
                            <PlanSponsor />
                        </Route>

                        <Route exact path={`${AppRoutes.AdminUsers}`}>
                            <AdminUserModule />
                        </Route>

                        <Route exact path={`${AppRoutes.CreateAdmin}`}>
                            <CreateAdmin isCreateMode={!isCreateMode} />
                        </Route>

                        <Route exact path={`${AppRoutes.PolicyModule}`}>
                            <PolicyModule />
                        </Route>
                    </Switch>
                    <FooterComponent />
                </div>
            </div>
        </Router>
    );
};

export default Landing;
