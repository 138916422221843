/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

interface PdfViewerProps {
    pdfUrl: string | null;
    onClose: () => void;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ pdfUrl, onClose }) => {
    const [file, setFile] = useState<string | null>(null);

    useEffect(() => {
        if (pdfUrl) {
            setFile(pdfUrl);
        }
    }, [pdfUrl]);

    return (
        <div
            style={{
                width: '70vw',
                height: '80vh',
                position: 'relative',
                margin: '0 auto',
                border: '1px solid #ddd',
                borderRadius: '8px',
                overflow: 'hidden',
            }}>
            {pdfUrl ? (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            marginTop: '40px',
                        }}>
                        <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
                            <Viewer fileUrl={pdfUrl} />
                        </Worker>
                    </div>
                </>
            ) : (
                <div>Loading PDF...</div>
            )}
        </div>
    );
};

export default PdfViewer;
