export const USERS = 'user';
export const ORDER_LIST_ENTITY = 'orders';
export const ASSETS_ENTITY = '/assets';
export const LOCATION_TYPES_ENTITY = '/location-types';
export const VARIANTS_ENTITY = '/variants';
export const AVAILABLE_DELIVERY_PARTNERS = '/delivery-partners/available';
export const RESET_PASSWORD = 'auth/reset-password';
export const VERIFY_EMAIL = 'auth/verify-email';
export const FORGOT_PASSWORD_EMAIL_SEND = 'admin/email/send';
export const RESET_FORGOT_PASSWORD = 'admin/reset-password';
export const VERIFY_ADMIN_EMAIL = 'admin/verify-email';
export const UPDATE_PASSWORD = 'admin/update-password';
export const UPDATE_USER_PASSWORD = 'admin/update-user-password';
export const APPLICATION = 'applications';
export const PLAN_SPONSOR = 'companies';
export const APPLICATION_AUTH_USER = 'applications/assign-auth-user';
export const USER_TITLES = 'user/unique-titles';
export const ADMIN_USERS = 'user/admin';
export const DOCUMENT_REVIEW_ACTION = 'documents/policy/status';
export const POLICY_MODULE = 'policies/list';
export const USER_DETAIL_BY_EMAIL = 'user/find-by-email';
export const APPLICATION_FILTER = 'applications/filter';
export const APPLICATIONS_ASSIGN_USER = 'applications/assign-user';
export const ACCOUNTING_EMAIL = 'payment/active-emails';
