// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-modal {
  width: 654px !important;
  height: 460px !important;
  border-radius: 8px !important; }
  .action-modal .ant-modal-content {
    border-radius: 8px; }
  .action-modal .ant-modal-title {
    font-size: 20px;
    font-weight: 600; }
  .action-modal .decline-btn {
    width: 122px;
    height: 44px;
    color: #000;
    border: 1x solid #CBCBCB;
    border-radius: 8px !important;
    background-color: #FFF !important; }
  .action-modal .approve-btn {
    width: 122px;
    height: 44px;
    color: #000 !important;
    border: 1x solid #E87524;
    border-radius: 8px !important;
    background-color: #E87524 !important; }
  .action-modal .ant-input {
    height: 219px;
    border: 1px solid #CBCBCB;
    border-radius: 8px; }
  .action-modal .ant-modal-footer {
    display: flex;
    justify-content: flex-end; }
    .action-modal .ant-modal-footer button {
      margin-left: 10px; }
    .action-modal .ant-modal-footer .ant-btn-primary {
      background-color: #f57600;
      border-color: #f57600; }
      .action-modal .ant-modal-footer .ant-btn-primary:hover, .action-modal .ant-modal-footer .ant-btn-primary:focus {
        background-color: #e26500;
        border-color: #e26500; }
`, "",{"version":3,"sources":["webpack://./src/components/ActionRequiredModal/style.scss"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,wBAAwB;EACxB,6BAA6B,EAAA;EAH/B;IAMI,kBAAkB,EAAA;EANtB;IAUI,eAAe;IACf,gBAAgB,EAAA;EAXpB;IAeI,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,wBAAwB;IACxB,6BAA6B;IAC7B,iCAAiC,EAAA;EApBrC;IAwBI,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,wBAAwB;IACxB,6BAA6B;IAC7B,oCAAoC,EAAA;EA7BxC;IAiCI,aAAa;IACb,yBAAyB;IACzB,kBAAkB,EAAA;EAnCtB;IAuCM,aAAa;IACb,yBAAyB,EAAA;IAxC/B;MA2CQ,iBAAiB,EAAA;IA3CzB;MA+CQ,yBAAyB;MACzB,qBAAqB,EAAA;MAhD7B;QAoDU,yBAAyB;QACzB,qBAAqB,EAAA","sourcesContent":[".action-modal{\n  width: 654px !important;\n  height: 460px !important;\n  border-radius: 8px !important;\n\n  .ant-modal-content{\n    border-radius: 8px;\n  }\n\n  .ant-modal-title{\n    font-size: 20px;\n    font-weight: 600;\n  }\n\n  .decline-btn{\n    width: 122px;\n    height: 44px;\n    color: #000;\n    border: 1x solid #CBCBCB;\n    border-radius: 8px !important;\n    background-color: #FFF !important;\n  }\n\n  .approve-btn{\n    width: 122px;\n    height: 44px;\n    color: #000 !important;\n    border: 1x solid #E87524;\n    border-radius: 8px !important;\n    background-color: #E87524 !important;\n  }\n\n  .ant-input{\n    height: 219px;\n    border: 1px solid #CBCBCB;\n    border-radius: 8px;\n  }\n\n  .ant-modal-footer {\n      display: flex;\n      justify-content: flex-end;\n    \n      button {\n        margin-left: 10px;\n      }\n    \n      .ant-btn-primary {\n        background-color: #f57600;\n        border-color: #f57600;\n    \n        &:hover,\n        &:focus {\n          background-color: #e26500;\n          border-color: #e26500;\n        }\n      }\n    }\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
