/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Modal, Button, Select } from 'antd';
import './style.scss';

interface FilterModalProps {
    onSubmit?: (d: string) => void;
    onCancel: () => void;
    optionData?: any;
    visible: boolean;
}

const FilterModal: React.FC<FilterModalProps> = ({ onSubmit, onCancel, visible, optionData }) => {
    const [selectedFilterKey, setSelectedFilterKey] = useState<string>('');

    const handleSubmit = () => {
        onSubmit && onSubmit(selectedFilterKey);
        onCancel();
    };

    return (
        <Modal
            visible={visible}
            title='Action Required'
            onCancel={onCancel}
            className='action-modal'
            footer={[
                <Button key='cancel' onClick={onCancel} className='decline-btn'>
                    Cancle
                </Button>,
                <Button key='submit' type='primary' onClick={handleSubmit} className='approve-btn'>
                    Submit
                </Button>,
            ]}>
            <div className='filterBody'>
                <h3>Select Filter to Apply</h3>
                <Select
                    showSearch
                    placeholder='Select Filter Key'
                    allowClear
                    onSelect={(value) => setSelectedFilterKey(value)}>
                    {optionData.map((option: any) => (
                        <Select.Option key={option.id} value={option.name}>
                            {option.value}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        </Modal>
    );
};

export default FilterModal;
