/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Typography, Button, Modal } from 'antd';
import { InfoCircleOutlined, PlusSquareOutlined } from '@ant-design/icons';

import { useParams } from 'react-router-dom';

import DefaultTableHeader from '../../Entity/AbstractLanding/DefaultTableHeader';

const { Link } = Typography;
import SearchPlanSponsorModal from '../../../components/SearchPlanSponsorModal';
import callApi from '../../../Api/config';
import { ACCOUNTING_EMAIL, APPLICATION } from '../../../constants/EntityName';
import { toastError } from '../../../service/Toast';
import DocIcon from '../../../assets/svg/docIcon.svg';
import CommentImage from '../../../assets/svg/commentIcon.svg';
import './style.scss';
import PdfViewer from '../../../components/PdfViewer';
import { formatDateTime, formatWithCommas } from '../../../utils/functions';
import { getUserDetail } from '../../../service/CookieManager';

const ApplicationDetailPage: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [applicationData, setApplicationData] = useState<any>();
    const [applicationDocData, setApplicationDocData] = useState<any>();
    const [showCommentModal, setShowCommentModal] = useState<boolean>(false);
    const [isDocModalVisible, setIsDocModalVisible] = useState(false);
    const [accountingEmail, setAccountingEmail] = useState<string>('');
    const [docFilePath, setDocFilePath] = useState<{ path: string; name: string }>({ path: '', name: '' });
    const [policyDetail, setPolicyDetail] = useState<any>({});

    const userDetail: any = getUserDetail() ? JSON.parse(getUserDetail() || '{}') : null;

    const handleOpenModal = () => {
        setShowCommentModal(true);
    };

    const handleCloseModal = () => {
        setShowCommentModal(false);
    };

    const showModal = () => setIsModalVisible(true);
    const hideModal = () => {
        setIsModalVisible(false);
    };

    const { id } = useParams<{ id: string }>();

    const fetchApplicationData = async () => {
        try {
            const response = await callApi(`${APPLICATION}/${id}`, 'GET');
            if (response?.status) {
                setApplicationData(response?.data?.data?.results);
            } else {
                console.error('Failed to fetch application data');
            }
        } catch (error) {
            toastError('Error fetching application data');
        }
    };
    useEffect(() => {
        fetchApplicationData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchDocumentData = async () => {
        try {
            const response = await callApi(`documents/${id}`, 'GET');
            if (response?.status) {
                setApplicationDocData(response?.data?.data);
                const policyDetail = response?.data?.data?.find((item: any) => item?.category === 'Policy');

                setPolicyDetail({
                    updatedAt: policyDetail?.updatedAt,
                    updatedBy: policyDetail?.updatedBy?.fullName,
                });
            } else {
                console.error('Failed to fetch application data');
            }
        } catch (error) {
            toastError('Error fetching application data');
        }
    };

    useEffect(() => {
        fetchDocumentData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFileClick = async (filePath: string, fileName: string) => {
        try {
            const response = await fetch(filePath, { method: 'GET' });

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            const contentType = response.headers.get('Content-Type');

            if (contentType) {
                if (contentType === 'text/csv') {
                    const downloadName = fileName.endsWith('.csv') ? fileName : `${fileName}.csv`;
                    const link = document.createElement('a');
                    link.href = filePath;
                    link.download = downloadName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    const downloadName = fileName.endsWith('.xlsx') ? fileName : `${fileName}.xlsx`;
                    const link = document.createElement('a');
                    link.href = filePath;
                    link.download = downloadName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    setDocFilePath({ path: filePath, name: fileName });
                    setIsDocModalVisible(true);
                }
            } else {
                console.log('Could not determine file type.');
            }
        } catch (error) {
            console.error('Error fetching file type:', error);
        }
    };

    const today = new Date().toLocaleDateString('en-US');

    const handleCancel = () => {
        setIsDocModalVisible(false);
    };

    const handleDownload = async () => {
        try {
            const response = await fetch(docFilePath.path, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch the PDF file.');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', docFilePath.name + '.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the PDF:', error);
        }
    };

    const modalTitle = (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{docFilePath.name.replace('-', ' ')}</span>
            <Button
                type='primary'
                onClick={handleDownload}
                style={{
                    backgroundColor: '#E87524',
                    borderColor: '#E87524',
                    marginRight: '40px',
                    color: 'black',
                    fontWeight: '500',
                    paddingTop: '5px',
                    borderRadius: '5px',
                }}>
                Download PDF
            </Button>
        </div>
    );

    const fetchAccountingEmail = async () => {
        try {
            const response = await callApi(`${ACCOUNTING_EMAIL}/${id}`, 'GET');

            if (response?.status) {
                setAccountingEmail(response?.data?.data[0]);
            } else {
                toastError('Error fetching accounting email.');
            }
        } catch (error) {
            console.error('Error during API hit:', error);
        }
    };

    useEffect(() => {
        fetchAccountingEmail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const AuthUserNames = applicationData?.authorizedUsers?.map((item: any) => item?.authorizedUserId?.email);

    const formatName = (name: string = '') =>
        name
            .split(' ')
            .map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase())
            .join(' ');

    return (
        <div className='plan-sponsor-dashboard'>
            <DefaultTableHeader
                headerName={'Applications'}
                setSearchQuery={() => 'test'}
                setPhoneSearchQuery={() => 'test'}
                setCitySearchQuery={() => 'test'}
                setStateSearchQuery={() => 'test'}
                disableSearch={true}
                disableAdd={true}
                isShowUploadButton={true}
                applicationId={applicationData?.id}
                fetchDocumentData={fetchDocumentData}
                isCreateMode={true}
                applicationStatus={applicationData?.adminStatus}
                fetchApplicationData={fetchApplicationData}
                applicationDocData={applicationDocData}
            />
            <div className='application-info-header'>
                <div className='application-info-header-content'>
                    <h2>Application submitted date:</h2>
                    <p>{applicationData?.submittedDate?.replaceAll('/', '-')}</p>
                </div>
                <div className='application-info-header-content'>
                    <h2>Application Id:</h2>
                    <p>{applicationData?.id}</p>
                </div>
                <div className='application-info-header-content'>
                    <h2>Application status:</h2>
                    <p>{applicationData?.adminStatus}</p>
                </div>
            </div>
            <div className='application-info-header'>
                <div className='application-info-header-content'>
                    <h2>Premium Split:</h2>
                    <p>Default</p>
                </div>
                <div className='application-info-header-content'>
                    <h2>Submitted by:</h2>
                    <p>{applicationData?.submittedBy?.fullName}</p>
                </div>
                <div className='application-info-header-content'>
                    <h2>Premium split status:</h2>
                    {applicationData?.premiumSplitStatus ? (
                        <div className='approve-status'>
                            <InfoCircleOutlined />
                            <p>{applicationData?.premiumSplitStatus}</p>
                        </div>
                    ) : (
                        <p>-</p>
                    )}
                </div>
            </div>
            <div className='dashboard-row'>
                <div className='application-container-sponsor'>
                    <div className='app-container-plan-doc'>
                        <div className='info-card'>
                            <div className='info-card-heading'>
                                <h3>Plan sponsor information</h3>
                            </div>
                            <div className='plan-sponsor-body'>
                                <div className='plan-sponsor-body-container'>
                                    <h4 className='info-card-title'>{applicationData?.companyName}</h4>
                                    <p>{applicationData?.website}</p>
                                    <p>SIC: {applicationData?.sicCode}</p>
                                    <p>{applicationData?.address}</p>
                                    <p>
                                        {applicationData?.city}, {applicationData?.state}, {applicationData?.zipCode}
                                    </p>
                                </div>
                                <p className='layoff-info'>
                                    Planned layoff in next 120 days: {applicationData?.plannedLayoffs ? 'Yes' : 'No'}
                                </p>
                            </div>
                        </div>
                        <div className='documents-card'>
                            <div className='document-heading'>
                                <h3>Documents</h3>
                            </div>
                            <div className='documents-card-body'>
                                {applicationDocData?.map(
                                    (doc: any) =>
                                        doc.category !== 'Application-pdf' && (
                                            <Link
                                                key={doc.id}
                                                onClick={() => handleFileClick(doc?.filePath, doc?.category)}>
                                                <img src={DocIcon} alt='Doc Icon' width={12} height={14} />
                                                <span style={{ marginLeft: '30px' }}>{doc?.category}</span>
                                            </Link>
                                        ),
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='plan-user-table-container'>
                        <div className='user-plan-information-table'>
                            <div className='plan-user-heading'>
                                <h3>{`Plan information as of: ${applicationData?.detailsTill}`}</h3>
                            </div>
                            <div className='plan-info'>
                                <div className='info-row'>
                                    <span className='label'>Legal name of the plan:</span>
                                    <span className='value'>{applicationData?.planName}</span>
                                </div>
                                <div className='info-row'>
                                    <span className='label'>Plan number:</span>
                                    <span className='value'>{applicationData?.planNumber}</span>
                                </div>
                                <div className='info-row'>
                                    <span className='label'>Total eligible participants:</span>
                                    <span className='value'>
                                        {Number(applicationData?.totalEligibleParticipants)
                                            ? Number(applicationData?.totalEligibleParticipants).toLocaleString()
                                            : '0'}
                                    </span>
                                </div>
                                <div className='info-row'>
                                    <span className='label'>Total active participants:</span>
                                    <span className='value'>
                                        {Number(applicationData?.totalActiveParticipants)
                                            ? Number(applicationData?.totalActiveParticipants).toLocaleString()
                                            : '0'}
                                    </span>
                                </div>
                                <div className='info-row'>
                                    <span className='label'>Total current assets:</span>
                                    <span className='value'>
                                        {'$' +
                                            (applicationData?.totalCurrentPlanAssets
                                                ? formatWithCommas(applicationData?.totalCurrentPlanAssets)
                                                : '0')}
                                    </span>
                                </div>
                                <div className='info-row'>
                                    <span className='label'>Total outstanding loan balance:</span>
                                    <span className='value'>
                                        {'$' +
                                            (applicationData?.totalOutstandingLoanBalances
                                                ? formatWithCommas(applicationData?.totalOutstandingLoanBalances)
                                                : '0')}
                                    </span>
                                </div>

                                <div className='info-row'>
                                    <span className='label'>
                                        Total number of participants with an outstanding loan balance:
                                    </span>
                                    <span className='value'>
                                        {Number(applicationData?.totalParticipantsWithLoans)
                                            ? Number(applicationData?.totalParticipantsWithLoans).toLocaleString()
                                            : '0'}
                                    </span>
                                </div>
                            </div>
                            <div className='comment-section'>
                                <div>
                                    <img src={CommentImage} alt='comment image' />
                                </div>
                                <div className='comment-container'>
                                    {applicationDocData &&
                                    applicationDocData.some((item: any) => item?.category === 'OFAC-check') ? (
                                        applicationDocData
                                            .filter((item: any) => item?.category === 'OFAC-check')
                                            .map((item: any, index: number) => (
                                                <div key={index}>
                                                    <div className='comment-head'>
                                                        <p>
                                                            #{index + 1} {item.category}
                                                        </p>
                                                        <p
                                                            style={{
                                                                color: '#9f9f9f',
                                                                fontWeight: 'normal',
                                                                fontSize: '14px',
                                                            }}>
                                                            {formatDateTime(item?.updatedAt)} by{' '}
                                                            {item?.createdBy?.fullName
                                                                ?.split(' ')
                                                                .map(
                                                                    (word: any) =>
                                                                        word.charAt(0).toUpperCase() +
                                                                        word.slice(1).toLowerCase(),
                                                                )
                                                                .join(' ')}
                                                        </p>
                                                    </div>
                                                    <p
                                                        className='comment-text'
                                                        style={{
                                                            fontSize: '16px',
                                                            whiteSpace: 'normal',
                                                            wordWrap: 'break-word',
                                                        }}>
                                                        {item?.comment}
                                                    </p>
                                                    {applicationData?.comment && (
                                                        <div>
                                                            <div className='comment-head'>
                                                                <p>#2 Policy</p>
                                                                <p
                                                                    style={{
                                                                        color: '#9f9f9f',
                                                                        fontWeight: 'normal',
                                                                        fontSize: '14px',
                                                                    }}>
                                                                    {formatDateTime(
                                                                        policyDetail?.updatedAt ||
                                                                            applicationData?.updatedAt,
                                                                    )}{' '}
                                                                    by{' '}
                                                                    {formatName(
                                                                        policyDetail?.updatedBy
                                                                            ? policyDetail?.updatedBy
                                                                            : applicationData?.updatedBy?.fullName,
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <p
                                                                className='comment-text'
                                                                style={{
                                                                    fontSize: '16px',
                                                                    whiteSpace: 'normal',
                                                                    wordWrap: 'break-word',
                                                                }}>
                                                                {applicationData?.comment}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            ))
                                    ) : (
                                        <p>No comments</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='contacts-card'>
                    <div className='contact-heading'>
                        <h3>Company contacts</h3>
                    </div>
                    <div className='contact-section'>
                        <Button
                            onClick={showModal}
                            className='auth-user-btn'
                            type='primary'
                            icon={<PlusSquareOutlined />}>
                            Additional authorized user
                        </Button>
                        <h4 className='contact-card-auth-title'>Authorized user(s)</h4>
                        <div className='contact-card-body'>
                            {applicationData?.authorizedUsers.map((item: any) => (
                                <div key={item?.id} style={{ paddingTop: '15px' }}>
                                    <div style={{ paddingBottom: '15px' }}>
                                        <p>{item?.authorizedUserId?.fullName}</p>
                                        <p>{item?.authorizedUserId?.title}</p>
                                    </div>
                                    <div style={{ paddingBottom: '10px' }}>
                                        <p style={{ marginBottom: '0px' }}>Contact:</p>
                                        <p>{item?.authorizedUserId?.email}</p>
                                    </div>
                                    <hr />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='accounting-team'>
                        <h3>Accounting team</h3>
                        <div className='accounting-content'>
                            <p>{accountingEmail ? accountingEmail : '-'}</p>
                            <hr />
                            <h3>Applicant</h3>
                            <div className='accounting-subcontent'>
                                <p>{applicationData?.createdBy?.fullName}</p>
                                <p>{applicationData?.createdBy?.title}</p>
                                <p>{applicationData?.createdBy?.userCompany}</p>
                                <p>
                                    Contact: <br />
                                    {applicationData?.createdBy?.email}
                                </p>
                                <p>{applicationData?.createdBy?.phoneNumber}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SearchPlanSponsorModal
                isVisible={isModalVisible}
                onClose={hideModal}
                applicationId={applicationData?.id}
                refreshData={fetchApplicationData}
                planSponsorAuthName={AuthUserNames}
            />
            <Modal
                title={modalTitle}
                open={isDocModalVisible}
                onCancel={handleCancel}
                width={'80%'}
                footer={false}
                style={{ top: 80, left: 120 }}>
                <PdfViewer pdfUrl={docFilePath.path} onClose={() => setIsDocModalVisible(false)} />
            </Modal>
        </div>
    );
};

export default ApplicationDetailPage;
