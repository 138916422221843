import { errorMsg } from '../constants/messages';

const AdminUserListData = [
    {
        name: 'firstName',
        label: 'First Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'First Name' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'lastName',
        label: 'Last Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Last Name' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'email',
        label: 'Email',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Email' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'phoneNumber',
        label: 'Phone Number',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Phone Number' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'title',
        label: 'Title',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Title' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'action',
        label: 'Action',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Action', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: false,
    },
];

export { AdminUserListData };
