import { errorMsg } from '../constants/messages';

const policyListFormData = [
    {
        name: 'applicationId',
        label: 'Application Id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Application Id', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'policyId',
        label: 'Policy Id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Policy Id', allowAddRecordClick: true, openModal: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'companyName',
        label: 'Company Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Company Name' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'planName',
        label: 'Plan Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Plan Name' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'planNumber',
        label: 'Plan Number',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Plan Number' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'planStart',
        label: 'Start date',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Start date' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'planEnd',
        label: 'Expiration date',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Expiration date' },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'policyStatus',
        label: 'Status',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Status',
            fiterIcon: true,
            filterOptions: ['Expired', 'Issued'],
            filterKey: 'policiesStatus',
        },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { policyListFormData };
