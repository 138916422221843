import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';

const { SubMenu } = Menu;

import { entityArray } from '../../constants/AppConstant';

import { getUserDetail } from '../../service/CookieManager';
import './styles.scss';

/**
 * returns the key and the parent key for a given url
 */
const getKeys = (url: string, entityArr: any = entityArray) =>
    entityArr.reduce(
        (acc: string, entityObject: any) => {
            if (acc[0] !== '') {
                return acc;
            }

            if (entityObject.children) {
                const a = entityObject.children.find((child: { entityUrl: string }) => child.entityUrl === url);
                return a ? [a.key, entityObject.key] : [''];
            }

            return entityObject.entityUrl === url ? [entityObject.key] : [''];
        },
        [''],
    );

export type EntityArray = {
    key: string;
    entityName: string;
    entityUrl: string;
    moduleName: string;
    children?: EntityArray[];
};

interface SidebarProps {
    title?: string;
    logo?: string;
    entityArr: EntityArray[];
}

const Sidebar = (props: SidebarProps) => {
    const { entityArr } = props;

    const [activeKey, setActiveKey] = useState<string>(getKeys('/', entityArr)[0]);
    const [openKey, setOpenKey] = useState<string[]>(['']);

    const { pathname } = useLocation();

    const userDetail: any = getUserDetail() ? JSON.parse(getUserDetail() || '{}') : null;

    const userRoleName = userDetail?.role;

    const onItemSelect = (event: { keyPath: string[] }) => {
        const [, parentKey] = event.keyPath;

        parentKey && setOpenKey([parentKey]);
    };

    const onTitleClick = (event: { key: string }) => {
        const { key } = event;
        if (openKey.includes(key)) {
            setOpenKey([]);
        } else {
            setOpenKey([key]);
        }
    };

    useEffect(() => {
        const [itemKey, parentKey] = getKeys(pathname);

        setActiveKey(itemKey);
        setOpenKey([parentKey]);
    }, [pathname]);

    return (
        <div className='sidebar-holder'>
            <div className='sidebar-navigation'>
                <div className='sidebar-header'>
                    {props.logo ? (
                        <div style={{ height: '100px', display: 'flex', alignItems: 'center' }}>
                            <img src={props.logo} alt='Logo' style={{ width: '162px', height: '57px' }} />
                        </div>
                    ) : (
                        <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#ffffff', cursor: 'pointer' }}>
                            <span style={{ margin: '0 12px', verticalAlign: 'middle' }}> {props.title}</span>
                        </div>
                    )}
                </div>

                <Menu mode='inline' activeKey={activeKey} openKeys={openKey} onSelect={onItemSelect} theme='dark'>
                    {entityArr.map(({ key, entityName, entityUrl, children }) => {
                        if (entityName === 'Admin Management' && userRoleName === 'Admin') {
                            return null;
                        }
                        if (children) {
                            return (
                                <SubMenu key={key} title={entityName} onTitleClick={onTitleClick}>
                                    {children.map((child: EntityArray) => (
                                        <Menu.Item key={child.key}>
                                            <Link to={child.entityUrl}>{child.entityName}</Link>
                                        </Menu.Item>
                                    ))}
                                </SubMenu>
                            );
                        }
                        return (
                            <Menu.Item key={key} style={{ margin: 'auto' }}>
                                <Link to={entityUrl}>{entityName}</Link>
                            </Menu.Item>
                        );
                    })}
                </Menu>
            </div>
        </div>
    );
};

export default Sidebar;
