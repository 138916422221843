// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
  margin-top: 40px;
  border-top: 1px solid #e0e0e0;
  position: relative;
  bottom: 0; }
  .footer div {
    font-size: 16px;
    color: #000;
    font-weight: bold; }
`, "",{"version":3,"sources":["webpack://./src/components/FooterComponent/style.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;EAClB,gBAAgB;EAChB,6BAA6B;EAC7B,kBAAkB;EAClB,SAAS,EAAA;EARb;IAWQ,eAAe;IACf,WAAW;IACX,iBAAiB,EAAA","sourcesContent":[".footer {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    padding: 20px 15px;\n    margin-top: 40px;\n    border-top: 1px solid #e0e0e0;\n    position: relative;\n    bottom: 0;\n    \n    div {\n        font-size: 16px;\n        color: #000;\n        font-weight: bold;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
