/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Spin } from 'antd';
import moment from 'moment';

import useGetResponse from '../../../utils/useGetResponse';
import { InputInterface } from '../../../utils/forms/FormInterface';
import { getIncomingPayload } from '../../../utils/getIncomingPayload';
import { getOutgoingPayload } from '../../../utils/getOutgoingPayload';
import { getAllAccordions } from '../../../utils/forms/formFunctions';
import { formatDate, updateDatamodel, formatPhoneNumber, updateFormFields } from '../../../utils/functions';

import { toastError, toastSuccess } from '../../../service/Toast';
import callApi from '../../../Api/config';

import CheckboxGroup from '../../../components/CheckboxGroup';
import TextInput from '../../../components/TextInput';
import Email from '../../../components/Email';
import PinCode from '../../../components/PinCode';
import SelectInput from '../../../components/SelectInput';
import MultiSelectInput from '../../../components/MultiSelectInput';
import NumberInput from '../../../components/NumberInput';
import TimeSlotInput from '../../../components/TimeSlotInput';
import MultipleTextInput from '../../../components/MultipleTextInput';
import MultipleTwinInput from '../../../components/MultipleTwinInput';
import FileInput from '../../../components/FileInput';
import DateInput from '../../../components/DateInput';
import OptionalSelectInput from '../../../components/OptionalSingleSelect';
import TextEditor from '../../../components/TextEditor';
import DrawerInput from '../../../components/DrawerInput';

import { failedMsg } from '../../../constants/messages';
import {
    ORDER_LIST_ENTITY,
    ASSETS_ENTITY,
    LOCATION_TYPES_ENTITY,
    USERS,
    VARIANTS_ENTITY,
    AVAILABLE_DELIVERY_PARTNERS,
    USER_TITLES,
} from '../../../constants/EntityName';

import '../entity.scss';
import './style.scss';
import TextArea from '../../../components/TextArea';
import DynamicSelectInput from '../../../components/DynamicSelectInput';
import TableInput from '../../../components/TableInput';
import UserRolePermission from '../../../components/UserRolePermission';
import MultipleImageUpload from '../../../components/MultipleImageUpload';
import CustomTextEditor from '../../../components/CustomTextEditor';

export enum UpdateRequestType {
    Put = 'PUT',
    Patch = 'PATCH',
    Post = 'POST',
}

interface AbstractFormProps {
    headerName: string;
    entityName: string;
    nestedEntityName?: string;
    formTemplate: Array<InputInterface>;
    payloadProcessor?: (arg: any, arg2: any, arg3?: any) => any;
    additionalEntityName?: string | { name: string; getAllResponse?: boolean }[];
    entityId?: string;
    isCreateMode?: boolean;
    disabledButton?: { delete?: boolean; update?: boolean; cancel?: boolean };
    formDefaultPayload?: any;
    onSuccessfulUpdate?: (id?: string) => void;
    formHeader?: string | undefined;
    setDrawerVisible?: (props: boolean) => void;
    showDeleteDraft?: boolean;
    table?: React.ReactNode;
    closeTab?: (arg0: string, agr2: boolean) => void;
    updateRequired?: boolean;
    extraPayloadData?: any;
    updateRequestType?: UpdateRequestType;
    apiEndPoint?: string;
    apiMethod?: string;
    additionalButtons?: any[];
    recordDataCount?: any;
    userRole?: any;
}

const AbstractForm = (props: AbstractFormProps) => {
    const {
        entityName,
        isCreateMode,
        formTemplate: initialFormTemplate,
        updateRequired,
        additionalEntityName: incomingAdditionalEntityName,
        extraPayloadData,
        nestedEntityName,
        updateRequestType = UpdateRequestType.Patch,
        additionalButtons,
        recordDataCount,
        userRole,
    } = props;

    const [defaultFormData] = useState(props.formDefaultPayload);
    const [incomingFormData, setIncomingFormData] = useState<any>();
    const [formTemplate, setFormTemplate] = useState(initialFormTemplate);
    const [additionalEntityName, setAdditionalEntityName] = useState(incomingAdditionalEntityName);
    const additionalData = useGetResponse(additionalEntityName);
    const [loading, setLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const statusRef = useRef<string>();
    const [form] = Form.useForm();

    /**
     * This useEffect is used to update the formTemplate file which is defined in the datamodels folder. It can be required in cases where the options for certain field are not known apriori and are obtained through API call
     */
    useEffect(() => {
        const updateFormTemplate = () => {
            switch (entityName) {
                case USERS: {
                    const userTitleList = additionalData?.[USER_TITLES]?.data;

                    const check = userTitleList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'title') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: userTitleList?.map((item: string) => ({
                                                value: item,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case ASSETS_ENTITY: {
                    const locationTypesOptions = additionalData?.[LOCATION_TYPES_ENTITY]?.data?.results;

                    const check = locationTypesOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'locationType') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: locationTypesOptions.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }
            }
        };

        updateFormTemplate();
    }, [additionalData, entityName, isCreateMode, incomingFormData]);

    /**
     * this function fetches data from API for any specific course/roles etc and sets that as the form field values. This function is not called while creating a new course/role.
     * @param coursesWorkshopResponseId
     */
    const getApiData = async () => {
        setLoading(true);

        let entityData: any = await callApi(`${props.entityName}/${props.entityId}`, 'GET');

        if (!isCreateMode && entityName === USERS) {
            entityData = {
                ...entityData,
                roleName: entityData?.role?.roleName,
            };

            const formTemplateData = formTemplate.filter((item: any) => item.name !== 'password');
            setFormTemplate(formTemplateData);
        }

        if (entityData?.status && entityData?.data) {
            setIncomingFormData(entityData?.data?.data);
            const data = getIncomingPayload(props.entityName, entityData?.data?.data);

            if (entityName === USERS) {
                data.roleName = data?.role?.roleName;
                data.phoneNumber = formatPhoneNumber(data?.phoneNumber);
            }

            const defaultObj: any = {};

            for (const key in props.formDefaultPayload) {
                if (key === 'startDate' || key === 'endDate') {
                    defaultObj[key] = data[key] && moment(data[key]);
                } else {
                    defaultObj[key] = data[key] ?? [];
                }
            }

            if (entityName === VARIANTS_ENTITY) {
                form.setFieldsValue(data);
            } else {
                form.setFieldsValue(defaultObj);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        const validEntityId = props.entityId || props.entityId === '';
        if (!props.isCreateMode && validEntityId) {
            getApiData();
        }
    }, [props.isCreateMode, props.entityId]);

    /** To update the datamodel and the selected form values once both additional data and the specific data for this form have been fetched */
    useEffect(() => {
        if (entityName === ORDER_LIST_ENTITY) {
            const availableDeliveryPartners = additionalData?.[AVAILABLE_DELIVERY_PARTNERS]?.data?.results;

            if (availableDeliveryPartners) {
                updateDatamodel({
                    setFormTemplate,
                    incomingFormData,
                    availableDeliveryPartners,
                    entityId: props.entityId as string,
                });

                updateFormFields({ incomingFormData, form });
            } else {
                if (incomingFormData) {
                    const endPointsForDropdownData = [
                        {
                            name: AVAILABLE_DELIVERY_PARTNERS,
                            getAllResponse: true,
                            queryParams: { storeId: incomingFormData?.store?.id },
                        },
                    ];
                    setAdditionalEntityName(endPointsForDropdownData);
                }
            }
        }
    }, [additionalData, entityName, form, incomingFormData, props.entityId]);

    const submitForm = (rawPayload: any) => {
        /**
         * the payloadProcessor function returns the payload in the format which can be sent in the request. If payloadProcessor function is not passed, then the reduce method is used to process the payload as default
         */

        const payload = props.payloadProcessor
            ? props.payloadProcessor(rawPayload, additionalData, incomingFormData)
            : getOutgoingPayload(
                  entityName,
                  rawPayload,
                  additionalData,
                  extraPayloadData,
                  incomingFormData,
                  nestedEntityName,
                  isCreateMode,
              );

        if (props.isCreateMode) {
            createEntity(payload, props.entityName);
        } else {
            updateEntity(payload);
        }
    };

    const createEntity = async (payload: any, entityName: string) => {
        setLoading(true);

        try {
            const res: any = await callApi(entityName, 'POST', payload);

            if (res?.status) {
                toastSuccess(`${props.headerName} created successfully!`);
                props.onSuccessfulUpdate?.('create');
            } else {
                toastError(
                    `Oops, an error occured! ${props.headerName} creation failed. ${res?.data?.message || failedMsg}`,
                );
            }
        } catch (err) {
            toastError(
                `Oops, an error occured! ${props.headerName} creation failed. Please contact development support team`,
            );
        }
        setLoading(false);
    };

    const updateEntity = async (payload: any) => {
        setLoading(true);
        if (entityName === 'user') {
            payload = {
                ...payload,
                phoneNumber: payload.phoneNumber.replace(/\D/g, ''),
            };
        }
        try {
            const url = props.nestedEntityName || `${props.entityName}/${props.entityId}`;

            const res: any = await callApi(url, updateRequestType, payload);

            if (res?.status) {
                toastSuccess(`${props.formHeader} updated successfully!`);
                props.entityId && props.closeTab?.(props.entityId, true);
            } else {
                toastError(
                    `Oops, an error occured! ${props.formHeader} updation failed. ${res?.data?.message || failedMsg}`,
                );
            }
        } catch (err) {
            toastError(
                `Oops, an error occured! ${props.formHeader} updation failed. Please contact development support team`,
            );
        }

        setLoading(false);
    };

    const onPublish = () => {
        statusRef.current = 'Published';
        form.submit();
    };

    const onUpdate = () => {
        form.submit();
    };

    const onDelete = async () => {
        setLoading(true);

        try {
            const res: any = await callApi(`${props.entityName}/${props.entityId}`, 'DELETE');

            if (res?.status) {
                toastSuccess(`${props.formHeader} deleted successfully!`);
                props.entityId && props.closeTab?.(props.entityId, true);
            } else {
                toastError(
                    `Oops, an error occured! ${props.formHeader} deletion failed. ${res?.data.message || failedMsg}`,
                );
            }
        } catch (err) {
            toastError(
                `Oops, an error occured! ${props.formHeader} deletion failed. Please contact development support team`,
            );
        }

        setLoading(false);
    };

    function getFormElement(formTemplate: InputInterface, i: number) {
        switch (formTemplate?.fieldType?.name) {
            case 'checkboxGroup': {
                return <CheckboxGroup formInput={formTemplate} index={i} form={form} />;
            }
            case 'email': {
                return <Email formInput={formTemplate} index={i} />;
            }
            case 'pinCode': {
                return <PinCode formInput={formTemplate} index={i} />;
            }
            case 'number': {
                return <NumberInput formInput={formTemplate} index={i} />;
            }
            case 'select': {
                return (
                    <SelectInput
                        formInput={formTemplate}
                        setFormData={updateRequired && setFormTemplate}
                        form={form}
                        index={i}
                        isCreateMode={isCreateMode}
                        setIsAdmin={setIsAdmin}
                    />
                );
            }
            case 'table': {
                return (
                    <TableInput
                        value={defaultFormData[formTemplate.name]}
                        name={formTemplate.name}
                        formTemplate={formTemplate}
                        form={form}
                        index={i}
                        entityId={props.entityId}
                    />
                );
            }
            case 'dynamicSelect': {
                return (
                    <DynamicSelectInput
                        formInput={formTemplate}
                        setFormData={updateRequired && setFormTemplate}
                        form={form}
                        index={i}
                        isCreateMode={isCreateMode}
                    />
                );
            }
            case 'multiSelect': {
                return <MultiSelectInput formInput={formTemplate} form={form} index={i} isCreateMode={isCreateMode} />;
            }
            case 'optionalSingleSelect': {
                return <OptionalSelectInput formInput={formTemplate} index={i} />;
            }
            case 'timeSlot': {
                return <TimeSlotInput formInput={formTemplate} index={i} />;
            }
            case 'multipleTextInput': {
                return (
                    <MultipleTextInput
                        formInput={formTemplate}
                        form={form}
                        entityName={props.nestedEntityName}
                        formHeader={props.formHeader}
                        setLoading={setLoading}
                        index={i}
                    />
                );
            }
            case 'multiImageUpload': {
                return (
                    <MultipleImageUpload formInput={formTemplate} form={form} index={i} isCreateMode={isCreateMode} />
                );
            }

            case 'textEditor': {
                return <TextEditor formInput={formTemplate} index={i} />;
            }
            case 'multipleTwinInput': {
                return <MultipleTwinInput formInput={formTemplate} index={i} />;
            }
            case 'drawerForm': {
                return (
                    <DrawerInput
                        entityId={props.entityId}
                        formInput={formTemplate}
                        form={form}
                        onSuccessfulUpdate={props.onSuccessfulUpdate}
                        isCreateMode={isCreateMode}
                    />
                );
            }
            case 'file':
            case 'image':
            case 'video': {
                return <FileInput formInput={formTemplate} index={i} form={form} />;
            }

            case 'documitraFile':
            case 'documitraImage':
            case 'documitraVideo': {
                return <FileInput formInput={formTemplate} index={i} form={form} />;
            }

            case 'uploadFile':
            case 'uploadImage':
            case 'uploadVideo': {
                return <FileInput formInput={formTemplate} index={i} form={form} />;
            }
            case 'photomitraFile':
            case 'photomitraImage':
            case 'photomitraVideo': {
                return <FileInput formInput={formTemplate} index={i} form={form} />;
            }
            case 'date': {
                return <DateInput formInput={formTemplate} index={i} />;
            }

            case 'customTextEditor': {
                return <CustomTextEditor formInput={formTemplate} index={i} />;
            }

            case 'textarea': {
                return <TextArea formInput={formTemplate} index={i} />;
            }
            case 'permissions': {
                return !isAdmin ? <UserRolePermission formTemplate={formTemplate} index={i} form={form} /> : null;
            }
            default: {
                return <TextInput formInput={formTemplate} index={i} form={form} entityName={entityName} />;
            }
        }
    }
    const allAccordions = getAllAccordions(formTemplate, getFormElement);

    return (
        <Spin spinning={loading}>
            <div className={'abstract-form'}>
                <div className='container1'>
                    <div className='form-container'>
                        <Form
                            form={form}
                            scrollToFirstError={true}
                            initialValues={defaultFormData}
                            onFinish={submitForm}>
                            {allAccordions}
                        </Form>
                    </div>
                </div>
                <div className='btn-container'>
                    <div className='publish-btn-container'>
                        <div className='publish-btn'>
                            {!props.isCreateMode && (
                                <Button type='primary' disabled={props.disabledButton?.update} onClick={onUpdate}>
                                    Update
                                </Button>
                            )}
                        </div>
                        <div className='publish-btn'>
                            {!props.isCreateMode && entityName !== USERS && (
                                <Button
                                    type='primary'
                                    disabled={
                                        props.disabledButton?.delete || recordDataCount < 2 || userRole === 'superadmin'
                                    }
                                    onClick={onDelete}>
                                    Delete
                                </Button>
                            )}

                            {props.isCreateMode && (
                                <Button type='primary' onClick={onPublish}>
                                    Create
                                </Button>
                            )}
                        </div>
                        <div className='publish-btn'>
                            <Button
                                type='primary'
                                disabled={props.disabledButton?.cancel}
                                onClick={() => {
                                    props.closeTab?.(props.entityId || 'a', false);
                                }}>
                                Cancel
                            </Button>
                        </div>
                        {additionalButtons?.map((button) => {
                            const { getOnClickHandler, text, key, isDisabled } = button;
                            return (
                                <Button
                                    type='primary'
                                    onClick={getOnClickHandler({
                                        entityId: props.entityId,
                                        setLoading,
                                        onRefresh: getApiData,
                                    })}
                                    key={key}
                                    disabled={isDisabled(incomingFormData)}>
                                    {text}
                                </Button>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Spin>
    );
};

AbstractForm.defaultProps = {
    isCreateMode: true,
    entityId: null,
    formDefaultPayload: {},
    hidePreview: false,
};

export default AbstractForm;
