/* eslint-disable no-console */
import { Form, Input, Select, Button, AutoComplete } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import DefaultTableHeader from '../Entity/AbstractLanding/DefaultTableHeader';
import callApi from '../../Api/config';
import { APPLICATION_FILTER, APPLICATIONS_ASSIGN_USER, USERS } from '../../constants/EntityName';
import { toastError, toastSuccess } from '../../service/Toast';
import UsaFlag from '../../assets/png/Flag_usa.png';

import './style.scss';
import { REGEX_NAME } from '../../utils/Regexp/Regex';
import { AppRoutes } from '../../constants/AppConstant';

const { Option } = Select;

const CreateUser = () => {
    const [createUserForm] = Form.useForm();
    const history = useHistory();
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
    const [titleOptionData, setTitleOptionData] = useState<any>();
    const [prevLocation, setPrevLocation] = useState<string>();
    const [applicationFilterData, setApplicationFilterData] = useState<any>();

    const [isCompanyEnabled, setIsCompanyEnabled] = useState(false);
    const [isAppIdEnabled, setIsAppIdEnabled] = useState(false);
    const [isPlanEnabled, setIsPlanEnabled] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [isAssignLoading, setIsAssignLoading] = useState(false);
    const [isCreateLoading, setIsCreateLoading] = useState(false);

    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedPlan, setSelectedPlan] = useState('');

    const location: any = useLocation();
    const [form] = Form.useForm();

    useEffect(() => {
        if (location.state?.from) {
            setPrevLocation(location.state.from);
        }
    }, [location.state]);

    const onValuesChange = (changedValues: any, allValues: any) => {
        const { user, companyName, applicationId, planName } = allValues;

        if (user) {
            setIsCompanyEnabled(true);
            setIsAppIdEnabled(true);
        } else {
            setIsCompanyEnabled(false);
            setIsAppIdEnabled(false);
        }

        if (applicationId?.length && !companyName) {
            setIsCompanyEnabled(false);
            setIsPlanEnabled(false);
        }

        if (!applicationId?.length && user) {
            setIsCompanyEnabled(true);
            setIsPlanEnabled(false);
        }

        if (companyName && !planName) {
            setIsAppIdEnabled(false);
        }

        if (companyName) {
            setIsPlanEnabled(true);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        setIsCompanyEnabled(false);
        setIsAppIdEnabled(false);
        setIsPlanEnabled(false);
        history.push('/user');
    };

    const onFinish = async (value: any) => {
        const payload = {
            ...value,
            phoneNumber: value.phoneNumber.replace(/-/g, ''),
            fullName: value.firstName + ' ' + value.lastName,
        };

        setIsCreateLoading(true);

        delete payload.firstName;
        delete payload.lastName;

        try {
            const response = await callApi(USERS, 'POST', payload);

            if (response?.status) {
                toastSuccess('User Created successfully!');
                history.push('/user');
            } else {
                toastError(response?.data?.error);
            }
        } catch (error) {
            console.error('Error during API hit:', error);
        } finally {
            setIsCreateLoading(false);
        }
    };

    const onAssignUserFinish = async (value: any) => {
        setIsAssignLoading(true);
        const formData = {
            ...value,
            userId: selectedUserId,
        };
        delete formData.user;

        try {
            const response = await callApi(APPLICATIONS_ASSIGN_USER, 'POST', formData);

            if (response?.status) {
                toastSuccess('User assigned successfully!');
                history.push('/user');
            } else {
                toastError(response?.data?.error);
            }
        } catch (error) {
            console.error('Error during API hit:', error);
        } finally {
            setIsAssignLoading(false);
        }
    };

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let input = e.target.value.replace(/\D/g, '');
        if (input.length > 6) {
            input = `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(6, 10)}`;
        } else if (input.length > 3) {
            input = `${input.slice(0, 3)}-${input.slice(3, 6)}`;
        }
        setFormattedPhoneNumber(input);
        createUserForm.setFieldsValue({ phoneNumber: input });
    };

    const onUserSelect = (value: string, option: any) => {
        setSelectedUserId(option.id);
        form.setFieldsValue({ user: value });
    };

    const fetchUserTitles = async () => {
        try {
            const response = await callApi(USERS, 'GET');

            if (response?.status) {
                setTitleOptionData(response?.data?.data?.results);
            } else {
                toastError('Error fetching User titles');
            }
        } catch (error) {
            console.error('Error during API hit:', error);
        }
    };

    const fetchApplicationDetails = async (companyName?: string, planName?: string) => {
        try {
            const queryParams: Record<string, string> = {};
            if (companyName) queryParams.companyName = companyName;
            if (planName) queryParams.planName = planName;

            const response = await callApi(APPLICATION_FILTER, 'GET', undefined, queryParams);
            if (response?.status) {
                setApplicationFilterData(response?.data?.data);
            } else {
                toastError('Error fetching application details');
            }
        } catch (error) {
            console.error('Error during API hit:', error);
        }
    };

    useEffect(() => {
        fetchApplicationDetails();
        fetchUserTitles();
    }, []);

    const handleCompanyClear = () => {
        setSelectedCompany('');
        fetchApplicationDetails(undefined, selectedPlan);
    };

    const handlePlanClear = () => {
        setSelectedPlan('');
        fetchApplicationDetails(selectedCompany, undefined);
    };

    const handleApplicationRouteClick = () => {
        history.push(AppRoutes.Users);
    };

    return (
        <div className='create-user-container'>
            <div style={{ paddingLeft: '15px' }}>
                <DefaultTableHeader
                    headerName={'User Management'}
                    setSearchQuery={() => 'test'}
                    setPhoneSearchQuery={() => 'test'}
                    setCitySearchQuery={() => 'test'}
                    setStateSearchQuery={() => 'test'}
                    disableSearch={true}
                    disableAdd={true}
                    isShowUploadButton={false}
                    isCreateMode={true}
                />
            </div>
            <div className='user-create-form-container'>
                <Form form={createUserForm} layout='vertical' className='user-create-form' onFinish={onFinish}>
                    <div className='form-row'>
                        <Form.Item
                            name='firstName'
                            label='First Name'
                            rules={[
                                { required: true, message: 'First Name is required' },
                                {
                                    pattern: REGEX_NAME,
                                    message: 'First Name can only contain letters and spaces',
                                },
                                { max: 100, message: 'First Name cannot exceed 100 characters' },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name='lastName'
                            label='Last Name'
                            rules={[
                                { required: true, message: 'Last Name is required' },
                                {
                                    pattern: REGEX_NAME,
                                    message: 'Last Name can only contain letters and spaces',
                                },
                                { max: 100, message: 'Last Name cannot exceed 100 characters' },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name='email'
                            label='Email'
                            rules={[
                                { required: true, message: 'Email is required' },
                                { type: 'email', message: 'Please enter a valid email' },
                                { max: 254, message: 'Email cannot exceed 254 characters' },
                            ]}>
                            <Input />
                        </Form.Item>
                    </div>
                    <div className='form-row'>
                        <Form.Item
                            name='phoneNumber'
                            label='Phone Number'
                            rules={[
                                { required: true, message: 'Phone Number is required' },
                                {
                                    pattern: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
                                    message: 'Please enter a valid phone number',
                                },
                            ]}>
                            <div className='us-flag-input'>
                                <div className='us-flag'>
                                    <img src={UsaFlag} alt='USA flag' width={34} height={18} />
                                    <span style={{ paddingLeft: '5px' }}>+1</span>
                                </div>
                                <Input value={formattedPhoneNumber} onChange={handlePhoneNumberChange} maxLength={12} />
                            </div>
                        </Form.Item>
                        <Form.Item
                            name='title'
                            label='Title'
                            rules={[{ required: true, message: 'Title is required' }]}>
                            <AutoComplete
                                options={titleOptionData?.map((item: any) => ({ value: item?.title }))}
                                filterOption={(inputValue, option: any) =>
                                    option ? option?.value?.toLowerCase().includes(inputValue.toLowerCase()) : false
                                }>
                                <Input />
                            </AutoComplete>
                        </Form.Item>
                        <Form.Item
                            name='roleName'
                            label='Role'
                            rules={[{ required: true, message: 'Role is required' }]}>
                            <Select>
                                <Option value='Plan Sponsor'>Plan Sponsor</Option>
                                <Option value='Advisor'>Advisor</Option>
                                <Option value='Agent'>Agent</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className='form-actions'>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button type='primary' htmlType='submit' loading={isCreateLoading}>
                            Save
                        </Button>
                    </div>
                </Form>

                {prevLocation !== '/applications' && (
                    <Form
                        form={form}
                        layout='vertical'
                        className='user-create-form'
                        onFinish={onAssignUserFinish}
                        onValuesChange={onValuesChange}>
                        <hr />
                        <div style={{ display: 'flex', marginTop: '15px' }}>
                            <p
                                onClick={handleApplicationRouteClick}
                                style={{
                                    fontSize: '24px',
                                    marginBottom: 0,
                                    cursor: 'pointer',
                                }}>
                                {`User Management >`}
                            </p>
                            <h2 style={{ marginLeft: '4px', fontWeight: 'bold', marginTop: '2px' }}>Assign User</h2>
                        </div>
                        <div className='form-row'>
                            <Form.Item
                                name='user'
                                label='Select user'
                                rules={[{ required: true, message: 'User is required' }]}>
                                <AutoComplete
                                    options={titleOptionData?.map((item: any) => ({
                                        value: item.fullName,
                                        id: item.id,
                                    }))}
                                    onSelect={onUserSelect}
                                    filterOption={(inputValue, option: any) =>
                                        option?.value?.toLowerCase().includes(inputValue.toLowerCase())
                                    }>
                                    <Input />
                                </AutoComplete>
                            </Form.Item>

                            <Form.Item name='companyName' label='Company name'>
                                <AutoComplete
                                    options={applicationFilterData?.companyNames?.map((name: string) => ({
                                        value: name,
                                    }))}
                                    filterOption={(inputValue, option: any) =>
                                        option ? option.value.toLowerCase().includes(inputValue.toLowerCase()) : false
                                    }
                                    disabled={!isCompanyEnabled}
                                    onSelect={(value) => {
                                        setSelectedCompany(value);
                                        fetchApplicationDetails(value, selectedPlan);
                                    }}
                                    onClear={handleCompanyClear}
                                    allowClear>
                                    <Input />
                                </AutoComplete>
                            </Form.Item>

                            <Form.Item name='planName' label='Plan name'>
                                <AutoComplete
                                    options={applicationFilterData?.planNames?.map((name: string) => ({
                                        value: name,
                                    }))}
                                    filterOption={(inputValue, option: any) =>
                                        option ? option.value.toLowerCase().includes(inputValue.toLowerCase()) : false
                                    }
                                    disabled={!isPlanEnabled}
                                    onSelect={(value) => {
                                        setSelectedPlan(value);
                                        fetchApplicationDetails(selectedCompany, value);
                                    }}
                                    onClear={handlePlanClear}
                                    allowClear>
                                    <Input />
                                </AutoComplete>
                            </Form.Item>
                        </div>

                        <div className='form-row'>
                            <Form.Item name='applicationId' label='Application ID Number' className='applicationForm'>
                                <Select
                                    className='application-id'
                                    mode='multiple'
                                    showSearch
                                    optionLabelProp='children'
                                    filterOption={(inputValue, option: any) =>
                                        option?.children?.toLowerCase().includes(inputValue.toLowerCase())
                                    }
                                    style={{ width: '100%' }}
                                    disabled={!isAppIdEnabled}>
                                    {applicationFilterData?.ids?.map((id: string) => (
                                        <Option key={id} value={id}>
                                            {id}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className='form-actions'>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button type='primary' htmlType='submit' loading={isAssignLoading}>
                                Save
                            </Button>
                        </div>
                    </Form>
                )}
            </div>
        </div>
    );
};

export default CreateUser;
