/* eslint-disable no-unused-vars */
export enum AppRoutes {
    Applications = '/applications',
    PlanSponsor = '/companies',
    Products = '/products',
    Users = '/user',
    OrderList = '/orderList',
    AuthResetPassword = '/auth/reset-password',
    AuthSignUp = '/auth/signup',
    ForgotPasswordEmailSend = '/admin/email/send',
    ResetForgotPassword = '/admin/reset-password',
    VerifyAdminEmail = '/admin/verify-email',
    UpdatePassword = '/admin/update-password',
    AdminUsers = '/user/admin',
    CreateAdmin = '/admin',
    CreateUser = '/create-user',
    CreatePlanSponsor = '/create-company',
    PolicyModule = '/policies/list',
    PremiumSplit = '/premium-split',
    ClaimModule = '/claims/admin-all',
}

export interface IApplicationDetail {
    application_id: string;
    companyName: string;
    planName: string;
    planNumber: string;
    totalAmount: string;
    premiumSchedule: {
        premiumScheduleName: string;
        premiumItems: IScheduleData[];
    };
    planSponsorName: string;
    premiumSplitStatus: string;
    comment: string;
}

export interface IScheduleData {
    key: string;
    premiumType: string;
    recipientName: string;
    allocationPercentage: number;
    finalAmount: string;
}

export interface IRowDetail {
    applicationId: string;
    companyName: string;
    planName: string;
    totalAmount: string;
    premiumScheduleName: string;
    premiumSplitProcessId: string;
    premiumSplitStatus: string;
}

export interface ISplitProcessExpandTableProps {
    premiumSplitProcessId: string;
    setCheckedRowKeys: (data: any) => void;
    fetchSplitProcessPendingData: () => void;
}

export interface IPendingDataType {
    id: string;
    createdAt: string;
    premiumSplitProcessId: string;
    premiumSplitStatus: string;
    nachaFilePath: string;
}

export interface IScheduleName {
    id: string;
    name: string;
}

export interface ISelectedRecord {
    applicationId: string;
    companyName: string;
    planName: string;
    premiumScheduleId: string;
    premiumSplitSchedule: IScheduleName;
}

export const formFields = [
    [
        { name: 'claimReason', label: 'Claim reason' },
        { name: 'separationDate', label: 'Separation date' },
        { name: 'outstandingLoanBalance', label: 'Outstanding loan balance' },
    ],
    [
        { name: 'payPeriod', label: 'Loan payment amount per pay period' },
        { name: 'payrollFrequency', label: 'Payroll frequency' },
        { name: 'totalClaimAmount', label: 'Total claim Amount' },
    ],
    [{ name: 'approvedOrRejectedBy', label: 'Approved/Rejected by' }],
];

const entityArray = [
    { key: 'item-1', entityName: 'Applications', entityUrl: AppRoutes.Applications },
    { key: 'item-2', entityName: 'Policies', entityUrl: AppRoutes.PolicyModule },
    { key: 'item-3', entityName: 'Manage Users', entityUrl: AppRoutes.Users },
    { key: 'item-4', entityName: 'Plan Sponsor Company Management', entityUrl: AppRoutes.PlanSponsor },
    { key: 'item-5', entityName: 'Premium Split', entityUrl: AppRoutes.PremiumSplit },
    { key: 'item-6', entityName: 'Claim Requests', entityUrl: AppRoutes.ClaimModule },
    { key: 'item-7', entityName: 'Admin Management', entityUrl: AppRoutes.AdminUsers },
];

export { entityArray };
