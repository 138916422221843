/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

import CryptoJS from 'crypto-js';

import { toastError, toastSuccess } from '../../service/Toast';
import { setToken, setUserDetail } from '../../service/CookieManager';
import { updateTokensAction } from '../actions';
import callApi from '../../Api/config';

import './styles.scss';
import { SECRET_KEY } from '../../env';

const LOGIN_URL = 'auth/admin/login';

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrorMessage('');
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!formData.email) {
            setErrorMessage('Please enter a valid email');
            return;
        }
        if (!formData.password) {
            setErrorMessage('Please enter a valid password');
            return;
        }

        setLoading(true);
        const encryptedPassword = CryptoJS.AES.encrypt(formData.password, SECRET_KEY as string).toString();
        const payload = { email: formData.email, password: encryptedPassword };

        const response: any = await callApi(LOGIN_URL, 'POST', payload);
        if (response?.data?.isSuccess && response?.data?.data) {
            const token = response?.data?.data?.token;
            setToken(token);
            dispatch(updateTokensAction(token));
            setUserDetail(JSON.stringify(response?.data?.data?.userDetails));
            history.push('/applications');
            toastSuccess('Login verified successfully');
        } else {
            toastError(response?.data?.error);
        }
        setLoading(false);
    };

    return (
        <div className='container'>
            <div className='logo-container'>{/* Optional logo or gradient background */}</div>
            <div className='form-container'>
                <div className='box'>
                    <h3>Login to Dashboard</h3>
                    <form onSubmit={onSubmit} className='login-form'>
                        <div className='form-group'>
                            <label htmlFor='email' className='email-label'>
                                Enter your email
                            </label>
                            <input
                                name='email'
                                id='email'
                                value={formData.email}
                                onChange={handleChange}
                                placeholder='Enter your email'
                                className='loginInput'
                            />
                            {<span className='error'>{errorMessage ? errorMessage : ' '}</span>}
                        </div>
                        <div className='form-group'>
                            <label htmlFor='password'>Enter your password</label>
                            <div className='password-input-wrapper'>
                                <input
                                    type={passwordVisible ? 'text' : 'password'}
                                    name='password'
                                    id='password'
                                    value={formData.password}
                                    onChange={handleChange}
                                    placeholder='Enter your password'
                                    className='loginInput'
                                />
                                <span className='toggle-password' onClick={togglePasswordVisibility}>
                                    {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                </span>
                            </div>
                            {<span className='error'>{errorMessage ? errorMessage : ' '}</span>}
                        </div>
                        <button type='submit' className='login-form-button' disabled={loading}>
                            {loading ? 'Logging in...' : 'Log In'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
