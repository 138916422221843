/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { Form, Input, Button, AutoComplete, Select } from 'antd';
import { useHistory } from 'react-router-dom';

import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useDispatch } from 'react-redux';

import DefaultProfileHeader from '../Entity/AbstractLanding/DefaultProfileHeader';
import DefaultTableHeader from '../Entity/AbstractLanding/DefaultTableHeader';
import callApi from '../../Api/config';
import { ADMIN_USERS, USER_TITLES, USERS } from '../../constants/EntityName';
import { toastError, toastSuccess } from '../../service/Toast';
import UsaFlag from '../../assets/png/Flag_usa.png';
import { AppRoutes } from '../../constants/AppConstant';
import ActionModals from '../../components/ActionModals';
import LockImg from '../../assets/svg/lockImg.svg';
import UpdateImg from '../../assets/svg/checkSuccess.svg';

import { updateAdminUserAction } from '../actions';
import { formatPhoneNumber } from '../../utils/functions';
import { REGEX_NAME, REGEX_PASSWORD_LENGTH_LIMIT, REGEX_PHONE_FORMATTED } from '../../utils/Regexp/Regex';
import './style.scss';
import { getUserDetail } from '../../service/CookieManager';

const { Option } = Select;

interface CreateAdminProps {
    isCreateMode: boolean;
    id?: string;
}

const CreateAdmin: React.FC<CreateAdminProps> = ({ isCreateMode }) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
    const [titleOptionData, setTitleOptionData] = useState<string[]>();
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState('');
    const [newAdminEmail, setNewAdminEmail] = useState('');
    const dispatch = useDispatch();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [password, setPassword] = useState('');

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const record = useSelector((state: any) => state?.globalReducer?.record);

    const userDetail: any = getUserDetail() ? JSON.parse(getUserDetail() || '{}') : null;

    const userRoleName = userDetail?.role;

    useEffect(() => {
        if (record) {
            form.setFieldsValue({
                firstName: record?.fullName?.split(' ')?.slice(0, -1)?.join(' '),
                lastName: record?.fullName?.split(' ')?.slice(-1)[0],
                email: record?.email,
                phoneNumber: formatPhoneNumber(record?.phoneNumber),
                title: record?.title,
            });
            setFormattedPhoneNumber(formatPhoneNumber(record?.phoneNumber));
        }
    }, [record, form]);

    const onFinish = async (value: any) => {
        const payload = {
            ...value,
            phoneNumber: value?.phoneNumber?.replace(/-/g, ''),
            fullName: value?.firstName + ' ' + value?.lastName,
            password: value?.password && value?.password,
        };

        delete payload.firstName;
        delete payload.lastName;

        try {
            let response;
            if (record.id) {
                response = await callApi(`${ADMIN_USERS}/${record.id}`, 'PATCH', payload);
            } else {
                response = await callApi(`${ADMIN_USERS}`, 'POST', payload);
            }

            setNewAdminEmail(response?.data?.data?.user?.email);

            if (response?.status) {
                if (record.id) {
                    setIsSuccessModalVisible(
                        value?.password
                            ? 'Your login credentials have been updated successfully.'
                            : 'Your details have been updated successfully.',
                    );
                } else {
                    setIsLoginModalVisible(true);
                }
                dispatch(updateAdminUserAction({}));
            } else {
                toastError(response?.data?.error);
            }
        } catch (error) {
            console.error('Error during API hit:', error);
        }
    };

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let input = e.target.value.replace(/\D/g, '');
        if (input.length > 6) {
            input = `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(6, 10)}`;
        } else if (input.length > 3) {
            input = `${input.slice(0, 3)}-${input.slice(3, 6)}`;
        }
        setFormattedPhoneNumber(input);
        form.setFieldsValue({ phoneNumber: input });
    };

    const fetchUserTitles = async () => {
        try {
            const response = await callApi(USER_TITLES, 'GET');

            if (response?.status) {
                setTitleOptionData(response?.data?.data);
            } else {
                toastError('Error fetching User titles');
            }
        } catch (error) {
            console.error('Error during API hit:', error);
        }
    };

    useEffect(() => {
        fetchUserTitles();
    }, []);

    const handleCancel = () => {
        dispatch(updateAdminUserAction({}));
        if (userRoleName === 'Super Admin') {
            history.push(AppRoutes.AdminUsers);
        } else {
            history.push(AppRoutes.Applications);
        }
    };

    const handleGoToAdminClick = () => {
        userRoleName === 'Admin' ? history.push(AppRoutes.Applications) : history.push(AppRoutes.AdminUsers);
    };

    return (
        <div className='create-admin-container'>
            <div style={{ paddingLeft: '15px' }}>
                <DefaultTableHeader
                    headerName={'Admin Management'}
                    setSearchQuery={() => 'test'}
                    setPhoneSearchQuery={() => 'test'}
                    setCitySearchQuery={() => 'test'}
                    setStateSearchQuery={() => 'test'}
                    disableSearch={true}
                    disableAdd={true}
                    isShowUploadButton={false}
                    isCreateMode={true}
                />
            </div>
            <div className='user-create-form-container'>
                <Form form={form} layout='vertical' className='user-create-form' onFinish={onFinish}>
                    <div className='form-row'>
                        <Form.Item
                            name='firstName'
                            label='First Name'
                            rules={[
                                { required: true, message: 'First Name is required' },
                                {
                                    pattern: REGEX_NAME,
                                    message: 'First Name can only contain letters and spaces',
                                },
                                { max: 100, message: 'First Name cannot exceed 100 characters' },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name='lastName'
                            label='Last Name'
                            rules={[
                                { required: true, message: 'Last Name is required' },
                                {
                                    pattern: REGEX_NAME,
                                    message: 'Last Name can only contain letters and spaces',
                                },
                                { max: 100, message: 'Last Name cannot exceed 100 characters' },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name='email'
                            label='Email'
                            rules={[
                                { required: true, message: 'Email is required' },
                                { type: 'email', message: 'Please enter a valid email' },
                                { max: 254, message: 'Email cannot exceed 254 characters' },
                            ]}>
                            <Input disabled={Object.keys(record).length > 0} />
                        </Form.Item>
                    </div>
                    <div className='form-row'>
                        <Form.Item
                            name='phoneNumber'
                            label='Phone Number'
                            rules={[
                                { required: true, message: 'Phone Number is required' },
                                {
                                    pattern: REGEX_PHONE_FORMATTED,
                                    message: 'Please enter a valid phone number',
                                },
                            ]}>
                            <div className='us-flag-input'>
                                <div className='us-flag'>
                                    <img src={UsaFlag} alt='USA flag' width={34} height={18} />
                                    <span style={{ paddingLeft: '5px' }}>+1</span>
                                </div>
                                <Input value={formattedPhoneNumber} onChange={handlePhoneNumberChange} maxLength={12} />
                            </div>
                        </Form.Item>
                        <Form.Item
                            name='title'
                            label='Title'
                            className={`${isCreateMode ? 'tilte_input' : ''}`}
                            rules={[{ required: true, message: 'Title is required' }]}>
                            <AutoComplete
                                options={titleOptionData?.map((title) => ({ value: title }))}
                                filterOption={(inputValue, option) =>
                                    option ? option.value.toLowerCase().includes(inputValue.toLowerCase()) : false
                                }>
                                <Input />
                            </AutoComplete>
                        </Form.Item>
                        {record?.id && (
                            <Form.Item
                                name='password'
                                label='Update Password'
                                rules={[
                                    {
                                        pattern: REGEX_PASSWORD_LENGTH_LIMIT,
                                        message: 'Please enter a valid password.',
                                    },
                                ]}>
                                <Input.Password placeholder='Enter your new password.' />
                            </Form.Item>
                        )}
                    </div>
                    <div className='form-actions'>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button type='primary' htmlType='submit'>
                            {record.id ? 'Save' : 'Generate password'}
                        </Button>
                    </div>
                </Form>
            </div>
            <ActionModals isVisible={isLoginModalVisible} onClose={() => setIsLoginModalVisible(false)}>
                <div className='login-content'>
                    <div className='login-icon'>
                        <img src={LockImg} alt='lock img' className='login-svg-img' height={80} width={95} />
                    </div>
                    <h2 className='login-title'>The login credentials have been shared on the email.</h2>
                    <p className='login-body'>{newAdminEmail}</p>
                    <div className='login-footer'>
                        <Button className='send-btn' type='primary' onClick={handleGoToAdminClick}>
                            Go to Admin Panel
                        </Button>
                    </div>
                </div>
            </ActionModals>

            <ActionModals isVisible={isSuccessModalVisible !== ''} onClose={() => setIsSuccessModalVisible('')}>
                <div className='update-content'>
                    <div className='update-icon'>
                        <img src={UpdateImg} alt='update img' className='update-svg-img' height={119} width={119} />
                    </div>
                    <h2 className='update-title'>{isSuccessModalVisible}</h2>
                    <div className='update-footer'>
                        <Button className='go-btn' type='primary' onClick={handleGoToAdminClick}>
                            {userRoleName === 'Super Admin' ? 'Go to Admin Panel' : 'Go to Applications'}
                        </Button>
                    </div>
                </div>
            </ActionModals>
        </div>
    );
};

export default CreateAdmin;
