/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { Form, Input, Select, Button } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DefaultProfileHeader from '../Entity/AbstractLanding/DefaultProfileHeader';
import DefaultTableHeader from '../Entity/AbstractLanding/DefaultTableHeader';
import callApi from '../../Api/config';
import { toastError, toastSuccess } from '../../service/Toast';
import { PLAN_SPONSOR } from '../../constants/EntityName';

import './style.scss';

const CreatePlanSponsor = () => {
    const [form] = Form.useForm();
    const [sicCodes, setSicCodes] = useState<string[]>([]);
    const [zipCode, setZipCode] = useState('');
    const [locationData, setLocationData] = useState<{ zipCode: string; city: string; state: string } | null>(null);

    const history = useHistory();

    useEffect(() => {
        const fetchSicCodes = async () => {
            try {
                const response = await callApi('companies/sic-code', 'GET');
                if (response?.status) {
                    setSicCodes(response.data.data);
                } else {
                    console.error('Failed to load SIC codes');
                }
            } catch (error) {
                console.error('Error fetching SIC codes');
            }
        };

        fetchSicCodes();
    }, []);

    const onFinish = async (value: any) => {
        const { city, state, ...rest } = value;
        const payload = {
            ...rest,
            zipCode: Number(rest.zipCode),
        };

        try {
            const response = await callApi(PLAN_SPONSOR, 'POST', payload);

            if (response?.status) {
                toastSuccess('User Created successfully!');
                history.push('/companies');
            } else {
                toastError('Error creating the User');
            }
        } catch (error) {
            console.error('Error during API hit:', error);
        }
    };

    const handleCancel = () => {
        history.push('/companies');
    };

    useEffect(() => {
        if (zipCode) {
            setTimeout(async () => {
                try {
                    const response = await callApi('companies/location', 'GET', null, { zipCode });
                    if (response?.data?.isSuccess) {
                        setLocationData(response?.data?.data?.data[0]);
                        form.setFieldsValue({
                            city: response?.data?.data?.data[0]?.city,
                            state: response?.data?.data?.data[0].state,
                        });
                    }
                } catch (error) {
                    console.error('Error fetching location data:', error);
                }
            }, 100);
        }
    }, [zipCode, form]);

    const onZipCodeBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setZipCode(e.target.value);
        form.setFieldsValue({
            city: '',
            state: '',
        });
        setLocationData(null);
    };

    return (
        <div className='create-plan-sponsor-container'>
            <DefaultProfileHeader />
            <div style={{ paddingLeft: '15px' }}>
                <DefaultTableHeader
                    headerName={'Plan Sponsor Management'}
                    setSearchQuery={() => 'test'}
                    setPhoneSearchQuery={() => 'test'}
                    setCitySearchQuery={() => 'test'}
                    setStateSearchQuery={() => 'test'}
                    disableSearch={true}
                    disableAdd={true}
                    isShowUploadButton={false}
                    isCreateMode={true}
                />
            </div>
            <div className='plan-sponsor-create-form-container'>
                <Form form={form} layout='vertical' className='plan-sponsor-form' onFinish={onFinish}>
                    <div className='form-row'>
                        <Form.Item
                            name='companyName'
                            label='Plan Sponsor Comapny Name*'
                            rules={[
                                { required: true, message: 'This field is required' },
                                { max: 100, message: 'Company Name cannot exceed 100 characters' },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name='sicCode'
                            label='SIC Code*'
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Select placeholder='Select SIC Code'>
                                {sicCodes.map((code, index) => (
                                    <Select.Option key={index} value={code}>
                                        {code}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name='website'
                            label='Website*'
                            rules={[
                                { required: true, message: 'This field is required' },
                                { max: 255, message: 'URL cannot exceed 255 characters' },
                            ]}>
                            <Input placeholder='Enter website URL' />
                        </Form.Item>
                    </div>
                    <div className='form-row'>
                        <Form.Item
                            name='address'
                            label='Address*'
                            rules={[
                                { required: true, message: 'This field is required' },
                                { max: 100, message: 'Address cannot exceed 100 characters' },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name='zipCode'
                            label='Zip code*'
                            rules={[
                                { required: true, message: 'This field is required' },
                                {
                                    pattern: /^[0-9]$/,
                                    message: 'Zip code must be only a number',
                                },
                            ]}>
                            <Input maxLength={6} value={zipCode} onChange={onZipCodeBlur} />
                        </Form.Item>
                        <Form.Item
                            name='city'
                            label='City*'
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input disabled={!locationData} />
                        </Form.Item>
                    </div>
                    <div className='form-row'>
                        <Form.Item
                            name='state'
                            label='State*'
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input disabled={!locationData} />
                        </Form.Item>
                    </div>
                    <div className='form-actions'>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button type='primary' htmlType='submit'>
                            Save
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default CreatePlanSponsor;
