/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Modal, Button, Input, AutoComplete } from 'antd';
import { DownOutlined, PlusSquareOutlined, SearchOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';

import { useHistory } from 'react-router-dom';

import callApi from '../../Api/config';
import { APPLICATION_AUTH_USER, USERS } from '../../constants/EntityName';
import { toastError } from '../../service/Toast';
import { AppRoutes } from '../../constants/AppConstant';

import './style.scss';

interface SearchPlanSponsorModalProps {
    isVisible: boolean;
    onClose: () => void;
    applicationId?: string;
    refreshData?: () => void;
    planSponsorAuthName: any;
}

const SearchPlanSponsorModal: React.FC<SearchPlanSponsorModalProps> = ({
    isVisible,
    onClose,
    applicationId,
    refreshData,
    planSponsorAuthName,
}) => {
    const [userNameData, setUserNameData] = useState<any[]>([]);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [searchValue, setSearchValue] = useState<string>('');
    const history = useHistory();

    const useDebounce = (value: string, delay: number = 500) => {
        const [debouncedValue, setDebouncedValue] = useState(value);

        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        }, [value, delay]);

        return debouncedValue;
    };

    const debouncedSearchValue = useDebounce(searchValue, 500);

    const onCreateUser = () => {
        history.push(AppRoutes.CreateUser, { from: AppRoutes.Applications });

        window.location.href = AppRoutes.CreateUser;
    };

    const fetchApplicationData = async (searchKey?: string) => {
        try {
            const response = await callApi(USERS, 'GET', undefined, searchKey ? { fullName: searchKey } : undefined);
            if (response?.status) {
                setUserNameData(response?.data?.data?.results);
            } else {
                console.error('Failed to fetch application data');
            }
        } catch (error) {
            toastError('Error fetching application data');
        }
    };

    useEffect(() => {
        if (debouncedSearchValue) {
            fetchApplicationData(debouncedSearchValue);
        } else {
            fetchApplicationData();
        }
    }, [debouncedSearchValue]);

    const onAddUser = async () => {
        if (!selectedUser) return;
        const payload = {
            userId: selectedUser.id,
            applicationId: applicationId,
        };
        try {
            const response = await callApi(APPLICATION_AUTH_USER, 'POST', payload);
            if (response?.status) {
                onClose();
                refreshData && refreshData();
            }
        } catch (error) {
            console.error('Error occurred', error);
        }
    };

    const handleSearch = (value: string) => {
        setSearchValue(value);
    };

    const handleSelect = (value: string) => {
        const selectedData = userNameData.find((item) => item.id === value);
        if (selectedData) {
            setSelectedUser(selectedData);
            setSearchValue(selectedData.fullName);
        }
    };

    const handleClose = () => {
        setSelectedUser(null);
        setSearchValue('');
        onClose();
    };

    return (
        <Modal visible={isVisible} onCancel={handleClose} footer={null} width={400} className='plan-sponsor-user-modal'>
            <div className='modal-icon'>
                <UserOutlined />
            </div>
            <h2>
                Select or search for plan <br />
                sponsor users
            </h2>
            <p>Quickly find and manage plan sponsor users.</p>
            <AutoComplete
                value={searchValue}
                options={userNameData?.map((item: any) => ({
                    value: item.id,
                    label: item.fullName,
                    disabled: planSponsorAuthName?.includes(item.fullName),
                }))}
                onSearch={handleSearch}
                onSelect={handleSelect}
                filterOption={(inputValue, option) => {
                    const label = option?.label?.toString().toLowerCase();
                    return label ? label.includes(inputValue.toLowerCase()) : false;
                }}
                dropdownStyle={{ width: 100 }}>
                <Input
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder='Search'
                    prefix={<SearchOutlined />}
                    suffix={<DownOutlined />}
                />
            </AutoComplete>
            <div className='modal-actions'>
                <Button icon={<UserAddOutlined />} onClick={onCreateUser} className='create-btn'>
                    Create user
                </Button>
                <Button type='primary' icon={<PlusSquareOutlined />} onClick={onAddUser} className='add-user-btn'>
                    Add user
                </Button>
            </div>
        </Modal>
    );
};

export default SearchPlanSponsorModal;
