import { errorMsg } from '../constants/messages';

const planSponsorListFormData = [
    {
        title: 'Plan Sponsor Details',
        name: 'id',
        label: 'Application Id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Application Id', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'companyName',
        label: 'Plan Sponsor Company Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Plan Sponsor Company Name' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'sicCode',
        label: 'SIC Code',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'SIC Code' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'website',
        label: 'Website',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Website' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'address',
        label: 'Address',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Address' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'createdBy',
        label: 'Created By',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Created By' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'planName',
        label: 'Plan Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Plan Name', enableSort: true },
        isTableHeader: true,
        isAccordian: true,
    },
    // this code may be in use in future
    // {
    //     name: 'createdAt',
    //     label: 'Created On',
    //     fieldType: {
    //         name: 'text',
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Created On', enableSort: true, tableKey: 'createdAt' },
    //     isTableHeader: true,
    //     isAccordian: true,
    // },
    // {
    //     name: 'updatedBy',
    //     label: 'Updated By',
    //     fieldType: {
    //         name: 'text',
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Updated By' },
    //     isTableHeader: true,
    //     isAccordian: true,
    // },
    // {
    //     name: 'updatedAt',
    //     label: 'Updated On',
    //     fieldType: {
    //         name: 'text',
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Updated On', enableSort: true, tableKey: 'updatedAt' },
    //     isTableHeader: true,
    //     isAccordian: true,
    // },
];

export { planSponsorListFormData };
