import React, { useState } from 'react';
import { Modal, Button, Input } from 'antd';

import './style.scss';
import callApi from '../../Api/config';
import { DOCUMENT_REVIEW_ACTION } from '../../constants/EntityName';
import { toastError, toastSuccess } from '../../service/Toast';

interface ActionRequiredModalProps {
    visible: boolean;
    onApproveDeclineAction: () => void;
    onCancel: () => void;
    applicationId: string | undefined;
}

const ActionRequiredModal: React.FC<ActionRequiredModalProps> = ({
    visible,
    onCancel,
    onApproveDeclineAction,
    applicationId,
}) => {
    const [reason, setReason] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [approveLoading, setApproveLoading] = useState<boolean>(false);
    const [declineLoading, setDeclineLoading] = useState<boolean>(false);

    const handleApproveDecline = async (value: boolean) => {
        if (!reason.trim()) {
            toastError('Please provide a reason before proceeding.');
            return;
        }

        setLoading(true);
        if (value) {
            setApproveLoading(true);
        } else {
            setDeclineLoading(true);
        }

        const payload = {
            policyStatus: value,
            applicationId,
            comment: reason,
        };
        try {
            const response = await callApi(`${DOCUMENT_REVIEW_ACTION}`, 'POST', payload);
            if (response?.status) {
                value ? toastSuccess('Application Approved') : toastError('Application Declined');
                onApproveDeclineAction();
                onCancel();
            }
        } catch (error) {
            toastError('An error occurred while processing the request.');
        } finally {
            setLoading(false);
            setApproveLoading(false);
            setDeclineLoading(false);
        }
    };

    return (
        <Modal
            visible={visible}
            title='Action Required'
            onCancel={onCancel}
            className='action-modal'
            footer={[
                <Button
                    key='decline'
                    onClick={() => handleApproveDecline(false)}
                    className='decline-btn'
                    loading={declineLoading}
                    disabled={loading}>
                    {' '}
                    Decline
                </Button>,
                <Button
                    key='approve'
                    type='primary'
                    onClick={() => handleApproveDecline(true)}
                    className='approve-btn'
                    loading={approveLoading}
                    disabled={loading}>
                    {' '}
                    Approve
                </Button>,
            ]}>
            <p>Please provide a reason for accepting or declining this policy.</p>
            <Input.TextArea maxLength={500} rows={4} value={reason} onChange={(e) => setReason(e.target.value)} />
        </Modal>
    );
};

export default ActionRequiredModal;
