/* eslint-disable no-unused-vars */
export enum AppRoutes {
    Applications = '/applications',
    PlanSponsor = '/companies',
    Products = '/products',
    Users = '/user',
    OrderList = '/orderList',
    AuthResetPassword = '/auth/reset-password',
    AuthSignUp = '/auth/signup',
    ForgotPasswordEmailSend = '/admin/email/send',
    ResetForgotPassword = '/admin/reset-password',
    VerifyAdminEmail = '/admin/verify-email',
    UpdatePassword = '/admin/update-password',
    AdminUsers = '/user/admin',
    CreateAdmin = '/admin',
    CreateUser = '/create-user',
    CreatePlanSponsor = '/create-company',
    PolicyModule = '/policies/list',
}
const entityArray = [
    { key: 'item-1', entityName: 'Applications', entityUrl: AppRoutes.Applications },
    { key: 'item-2', entityName: 'Policies', entityUrl: AppRoutes.PolicyModule },
    { key: 'item-3', entityName: 'Manage Users', entityUrl: AppRoutes.Users },
    { key: 'item-4', entityName: 'Plan Sponsor Company Management', entityUrl: AppRoutes.PlanSponsor },
    { key: 'item-5', entityName: 'Admin Management', entityUrl: AppRoutes.AdminUsers },
];

export { entityArray };
